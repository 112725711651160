import { faPlusCircle, faPlusHexagon } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";
import clsx from "clsx";

export function ProductTableAddFilter({ filters, setFilter }) {
  const [userFilters, setUserFilters] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const filtersOptions = useMemo(
    () => [
      {
        label: "Published",
        value: "published",
        component: ProductTablePublishedFilter,
      },
      {
        label: "Marketplace",
        value: "marketplace",
        component: ProductTableMarketplaceFilter,
      },
      {
        label: "Vehicles",
        value: "vehicles",
        component: ProductTableVehicleFilter,
      },
      {
        label: "Media",
        value: "media",
        component: ProductTableMediaFilter,
      },
      {
        label: "Listing Status",
        value: "status",
        component: ProductTableStatusFilter,
      },
    ],
    []
  );

  const addFilterOption = (filter) => {
    setUserFilters((prev) => [...prev, filter]);
    setIsOpen(false);
  };

  const clearAllFilters = () => {
    setUserFilters([]);
    filters.forEach((filter) => setFilter(filter.id, null));
  };

  const filteredOptions = filtersOptions.filter((filter) => !userFilters.includes(filter));

  return (
    <div className="flex items-center justify-start gap-2">
      {userFilters.map((filter) => {
        const FilterComponent = filter.component;
        return <FilterComponent key={filter.value} filters={filters} setFilter={setFilter} />;
      })}
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button
          className={clsx(
            "btn btn-outline btn-sm",
            filteredOptions && filteredOptions.length === 0 && "!hidden"
          )}
        >
          Add Filter
          <FontAwesomeIcon icon={faPlusCircle} className="ml-2 text-gray-500" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {filteredOptions.map((filter) => {
                return (
                  <Menu.Item key={filter.value}>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => addFilterOption(filter)}
                        className={clsx(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        {filter.label}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {userFilters && userFilters.length > 0 && (
        <button onClick={() => clearAllFilters()} className="link mx-2 whitespace-nowrap text-xs">
          Clear all
        </button>
      )}
    </div>
  );
}

export function ProductTableVehicleFilter({ filters, setFilter }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("has_vehicles", value || undefined);
  }, 200);

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      className="min-w-[110px] max-w-fit rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-xs shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Vehicles...</option>
      <option value={true}>Linked to vehicles</option>
      <option value={false}>Not linked to vehicles</option>
    </select>
  );
}

export function ProductTableStatusFilter({ filters, setFilter }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("listing_status", value || undefined);
  }, 200);

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      className="min-w-[110px] max-w-fit rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-xs shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Listing Status...</option>
      <option value="draft">Draft</option>
      <option value="published">Published</option>
      <option value="invalid">Invalid</option>
      <option value="archived">Archived</option>
    </select>
  );
}

export function ProductTableMediaFilter({ filters, setFilter }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("has_media", value || undefined);
  }, 200);

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      className="min-w-[110px] max-w-fit rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-xs shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Media...</option>
      <option value={true}>Has media</option>
      <option value={false}>No media</option>
    </select>
  );
}

export function ProductTableMarketplaceFilter({ filters, setFilter }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("has_listing", value || undefined);
  }, 200);

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      className="min-w-[110px] max-w-fit rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-xs shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Marketplace...</option>
      <option value={true}>Has listing</option>
      <option value={false}>No listing</option>
    </select>
  );
}

export function ProductTablePublishedFilter({ filters, setFilter }) {
  const [value, setValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setFilter("published", value || undefined);
  }, 200);

  return (
    <select
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      className="min-w-[110px] max-w-fit rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-xs shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
    >
      <option value="">Published...</option>
      <option value={true}>Published</option>
      <option value={false}>Unpublished</option>
    </select>
  );
}
