import React, { lazy, Suspense, useState, useEffect, Fragment } from "react";
import {
  useParams,
  useSearchParams,
  Link as RouterLink,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  getDeliveriesListQueryOptions,
  useDeliveriesList,
  deliveryDocument,
} from "../../../../api/partbot/deliveries/deliveries";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import Pagination from "../../components/Pagination";

export const loader =
  (queryClient) =>
  async ({ params, request }) => {
    const url = new URL(request.url);
    const page = url.searchParams.get("page") || "1";
    const page_size = url.searchParams.get("page_size") || "1";
    return await queryClient.ensureQueryData(
      getDeliveriesListQueryOptions({
        location_ids: [params.locationId],
        page: page,
        page_size: page_size,
        type: "shipped",
      })
    );
  };

export default function Shipped() {
  const { locationId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    data: { results: bookings, pagination },
    refetch,
  } = useDeliveriesList({
    location_ids: [locationId],
    page: searchParams.get("page"),
    page_size: searchParams.get("page_size"),
    type: "shipped",
  });

  console.log("Bookings", bookings, pagination);

  return (
    <div className="space-y-5">
      <BookingCards bookings={bookings} locationId={locationId} />
      {bookings?.length >= 1 && <Pagination pagination={pagination} />}
    </div>
  );
}

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const options = { month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const statusClass = (status) => {
  switch (status.toLowerCase()) {
    case "booked":
      return "bg-green-100 text-green-800";
    case "in transit":
      return "bg-blue-100 text-blue-800";
    case "delivered":
      return "bg-gray-100 text-gray-800";
    default:
      return "bg-yellow-100 text-yellow-800";
  }
};

const documents = [
  { type: "SHIPPING_MANIFEST", label: "Manifest" },
  { type: "SHIPPING_COMMERCIAL_INVOICE", label: "Commercial Invoice" },
  { type: "SHIPPING_LABEL", label: "Shipping Label" },
  { type: "SHIPPING_CONNOTE", label: "Connote" },
  { type: "SHIPPING_DG_DOCUMENT", label: "Dangerous Goods Declaration" },
  { type: "POD", label: "Proof of Delivery" },
];

const BookingCard = ({ booking, locationId, onCheckboxChange }) => {
  const [loading, setLoading] = useState(
    documents.reduce((acc, doc) => {
      acc[doc.label] = false;
      return acc;
    }, {})
  );

  const handleDownload = async (documentType, trackingNumber, fileNameSuffix) => {
    setLoading((prev) => ({ ...prev, [fileNameSuffix]: true }));
    try {
      const file = await deliveryDocument(booking.id, { document_type: documentType });
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${trackingNumber}_${fileNameSuffix}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading document", error);
    } finally {
      setLoading((prev) => ({ ...prev, [fileNameSuffix]: false }));
    }
  };

  const totalWeight = booking.items.reduce(
    (acc, item) => acc + item.unit_weight * item.quantity,
    0
  );
  const dangerousGoods = booking.items.some((item) => item.dangerous_goods); // Assuming there's a dangerous_goods field

  return (
    <div className="relative my-4 flex items-start justify-between rounded-lg border border-gray-200 p-4 text-sm shadow-sm">
      <input
        type="checkbox"
        onChange={(e) => onCheckboxChange(booking.id, e.target.checked)}
        className="mr-2"
      />
      <div className="flex-1">
        <div className="font-semibold text-gray-800">{booking.fe_tracking_number}</div>
        <div className="text-gray-500">
          {booking.references.reference1} {booking.references.reference2}{" "}
          {booking.references.reference3}
        </div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Origin</div>
        <div className="text-gray-600">
          {booking.origin.city}, {booking.origin.state}, {booking.origin.post_code},{" "}
          {booking.origin.country_code}
        </div>
        <div className="text-gray-500">{booking.origin.contact_name}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Destination</div>
        <div className="text-gray-600">
          {booking.destination.city}, {booking.destination.state}, {booking.destination.post_code},{" "}
          {booking.destination.country_code}
        </div>
        <div className="text-gray-500">{booking.destination.contact_name}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Carrier</div>
        <div className="text-gray-600">{booking.prices[0].carrier_name}</div>
        <div className="text-gray-500">{booking.prices[0].service_name}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Details</div>
        <div className="text-gray-600">Items: {booking.items.length}</div>
        <div className="text-gray-600">Weight: {totalWeight} kg</div>
        {dangerousGoods && <div className="font-semibold text-red-500">Dangerous Goods</div>}
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Dates</div>
        <div className="text-gray-600">Created: {formatDate(booking.create_date)}</div>
        <div className="text-gray-600">
          Pickup: {formatDate(booking.expected_pickup_dates?.earliest)} -{" "}
          {formatDate(booking.expected_pickup_dates?.latest)}
        </div>
        <div className="text-gray-600">
          Delivery: {formatDate(booking.expected_delivery_dates?.earliest)} -{" "}
          {formatDate(booking.expected_delivery_dates?.latest)}
        </div>
      </div>
      <div className="flex-1 text-right">
        <Menu as="div" className="relative inline-block text-left text-xs">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-3 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              Documents
              <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {documents.map((doc, i) => (
                  <Menu.Item key={doc.type}>
                    {({ active }) => (
                      <button
                        tabIndex={i === 0 ? 0 : -1}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(doc.type, booking.fe_tracking_number, doc.label);
                        }}
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } block flex w-full items-center px-4 py-2 text-left text-xs`}
                      >
                        {doc.label}
                        {loading[doc.label] ? (
                          <Spinner className="mr-8 h-3 w-3 animate-spin" />
                        ) : null}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="absolute bottom-4 left-4">
        <span
          className={`inline-block rounded px-2 py-1 text-xs font-semibold ${statusClass(
            booking.latest_event?.status
          )}`}
        >
          {booking.latest_event?.status}
        </span>
      </div>
    </div>
  );
};
const BookingCards = ({ bookings, locationId }) => {
  const [selectedBookings, setSelectedBookings] = useState([]);

  const handleCheckboxChange = (id, checked) => {
    setSelectedBookings((prev) => {
      if (checked) {
        return [...prev, id];
      } else {
        return prev.filter((bookingId) => bookingId !== id);
      }
    });
  };

  const handleBulkAction = (actionType) => {
    // Implement the bulk action logic here
    console.log(`Performing ${actionType} on: `, selectedBookings);
  };

  return (
    <div className="pt-1">
      {bookings?.map((booking) => (
        <BookingCard
          key={booking.id}
          booking={booking}
          locationId={locationId}
          onCheckboxChange={handleCheckboxChange}
        />
      ))}
    </div>
  );
};

const Spinner = () => (
  <svg
    className="ml-4 h-3 w-3 animate-spin text-indigo-700"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 2.686 8.165 6.553 9.747l1.447-2.456z"
    ></path>
  </svg>
);
