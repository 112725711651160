import React, { useState } from "react";
import parse from "html-react-parser";
import VariantDetail from "./VariantDetail";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";

const tabs = [
  { id: 0, name: "Description", current: true, showCount: false },
  { id: 1, name: "Variants", current: false },
  { id: 2, name: "Media", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductTabs({ listing }) {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="scrollbar-hide relative h-full overflow-scroll">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.id === activeTab).name}
        >
          {tabs.map((tab) => (
            <option key={tab.id}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="sticky top-0 hidden bg-white sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={classNames(
                  tab.id === activeTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-xs font-medium no-underline"
                )}
                aria-current={tab.id === activeTab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="mb-12 flex h-full w-full py-6">
        {listing.id ? (
          (() => {
            switch (activeTab) {
              case 0:
                return (
                  <div className="prose text-xs text-gray-500">{parse(listing.description)}</div>
                );
              case 1:
                return (
                  <div className="flex w-full flex-col space-y-3">
                    {listing.products.map((product) => {
                      return (
                        <Disclosure key={product.sku} className="w-full">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between rounded bg-indigo-100 px-4 py-2 text-left text-xs font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                                <span>SKU: {product.sku}</span>
                                <FontAwesomeIcon
                                  icon={open ? faChevronUp : faChevronDown}
                                  className="h-4 w-4 text-indigo-500"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                <VariantDetail variant={product} />
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      );
                    })}
                  </div>
                );
              case 2:
                return (
                  <div className="flex w-full gap-3">
                    {listing.products.map((product) => {
                      return product.media
                        .filter((media) => media.type === "image")
                        .map((media) => {
                          return (
                            <div key={media.id}>
                              <img
                                src={`https://res.cloudinary.com/proex/image/fetch/f_auto,w_200,h_200,c_pad,b_white,q_80,dpr_auto/${media.url}`}
                                className="h-24 w-24 overflow-hidden rounded-md border border-gray-200"
                                alt={product.title}
                              />
                            </div>
                          );
                        });
                    })}
                  </div>
                );

              default:
                return null;
            }
          })()
        ) : (
          <div className="text-sm text-gray-600">Error</div>
        )}
      </div>
    </div>
  );
}
