import React, { lazy, Suspense } from "react";
import { Tab } from "@headlessui/react";

import {
  useParams,
  Link as RouterLink,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  getLocationByIdQueryOptions,
  useLocationById,
} from "../../../../api/partbot/locations/locations";

export const loader =
  (queryClient) =>
  async ({ params }) => {
    return await queryClient.ensureQueryData(getLocationByIdQueryOptions(params.locationId));
  };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Location() {
  const { locationId } = useParams();
  const { data: location, refetch } = useLocationById(locationId);

  return (
    <div className="w-full space-y-4 rounded-xl border-b border-gray-200 bg-white p-4 shadow-xl">
      <Tabs warehouseName={`${location.name} (${location.code})`} locationId={locationId} />
      <Outlet />
    </div>
  );
}

const Tabs = ({ warehouseName, locationId }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tabs = [
    {
      name: "Pending",
      to: `/locations/${locationId}/shipments`,
      current: location.pathname.includes("shipments"),
    },
    {
      name: "Ready to Ship",
      to: `/locations/${locationId}/bookings`,
      current: location.pathname.includes("bookings"),
    },
    {
      name: "Shipped",
      to: `/locations/${locationId}/shipped`,
      current: location.pathname.includes("shipped"),
    },
  ];

  console.log("location path name", location.pathname);

  const ensureDefaultParams = () => {
    //if (!searchParams.has("page")) {
    searchParams.set("page", "1");
    //}
    if (!searchParams.has("page_size")) {
      searchParams.set("page_size", "10");
    }
  };
  const handleTabClick = (tab) => {
    ensureDefaultParams();
    const basePath = location.pathname.split("/").slice(0, -1).join("/");
    navigate(`${tab}?${searchParams.toString()}`);
  };

  const getSelectedIndex = () => {
    return tabs.findIndex((tab) => location.pathname.includes(tab.to));
  };

  return (
    <div className="-m-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) => handleTabClick(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden items-center justify-between border-b border-gray-200 sm:flex">
        <nav aria-label="Tabs" className="isolate flex w-1/3 divide-x divide-gray-200 rounded-t-lg">
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              aria-current={tab.current ? "page" : undefined}
              className={classNames(
                tab.current ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-tl-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-tr-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              )}
              onClick={() => handleTabClick(tab.to)}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
        <div className="px-4 text-lg font-semibold text-gray-800">{warehouseName}</div>
      </div>
    </div>
  );
};
