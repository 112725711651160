import React, { useReducer, createContext, useContext } from "react";
import { parse } from "tldts";
import { v4 as uuidv4 } from "uuid";

const ShipmentContext = createContext();

function shipmentReducer(state, action) {
  switch (action.type) {
    case "INITIALIZE": {
      return { ...state, ...action.payload };
    }

    case "MOVE_ITEM": {
      const { fromBoxId, toBoxId, itemId, quantity } = action.payload;
      const newState = { ...state };

      console.log("Action", action);
      console.log("State before move", newState);
      console.log("Moving item", itemId, "from", fromBoxId, "to", toBoxId, "quantity", quantity);

      // Update source box (if applicable)
      if (fromBoxId !== null) {
        const fromBox = newState.boxes.find((box) => box.id === fromBoxId);
        const itemInFromBox = fromBox.items.find((item) => item.id === itemId);
        itemInFromBox.quantity -= quantity;
        if (itemInFromBox.quantity === 0) {
          fromBox.items = fromBox.items.filter((item) => item.id !== itemId);
        }
      }

      // Update destination box
      if (toBoxId !== null) {
        const toBox = newState.boxes.find((box) => box.id === toBoxId);
        const itemInToBox = toBox.items.find((item) => item.id === itemId);
        if (itemInToBox) {
          itemInToBox.quantity += quantity;
        } else {
          toBox.items.push({ id: itemId, quantity });
        }
      } else {
        // Create a new box
        const newBox = {
          id: uuidv4(),
          items: [{ id: itemId, quantity }],
          dimensions: {
            length: 0,
            width: 0,
            height: 0,
          },
          weight: 0,
        };
        newState.boxes.push(newBox);
      }

      return newState;
    }

    case "UPDATE_BOX_DIMENSIONS": {
      const { boxId, dimensions } = action.payload;
      const newState = { ...state };

      const box = newState.boxes.find((box) => box.id === boxId);
      box.dimensions = {
        length: parseFloat(dimensions.length),
        width: parseFloat(dimensions.width),
        height: parseFloat(dimensions.height),
      };

      return newState;
    }

    case "UPDATE_BOX_WEIGHT": {
      const { boxId, weight } = action.payload;
      const newState = { ...state };

      const box = newState.boxes.find((box) => box.id === boxId);
      box.weight = parseFloat(weight);

      return newState;
    }

    case "ADD_NEW_BOX":
      return state;

    case "MERGE_BOXES": {
      return state;

      console.log("Action", action);
      console.log("State", state);
      const { fromBoxId, toBoxId } = action.payload;
      const newState = { ...state };
      const fromBox = newState.boxes.find((box) => box.id === fromBoxId);
      const toBox = newState.boxes.find((box) => box.id === toBoxId);

      fromBox.items.forEach((fromItem) => {
        const toItem = toBox.items.find((item) => item.id === fromItem.id);
        if (toItem) {
          toItem.quantity += fromItem.quantity;
        } else {
          toBox.items.push({ ...fromItem });
        }
      });

      newState.boxes = newState.boxes.filter((box) => box.id !== fromBoxId);

      return newState;
    }

    case "UNPACK_BOX": {
      const { boxId } = action.payload;
      const newState = { ...state };
      const box = newState.boxes.find((box) => box.id === boxId);
      // box.items.forEach((boxItem) => {
      //   const item = newState.items.find((item) => item.id === boxItem.id);
      //   item.quantity += boxItem.quantity;
      // });

      newState.boxes = newState.boxes.filter((box) => box.id !== boxId);

      return newState;
    }

    case "UNPACK_ITEM": {
      const { boxId, itemId } = action.payload;
      const newState = { ...state };
      const box = newState.boxes.find((box) => box.id === boxId);
      const boxItem = box.items.find((item) => item.id === itemId);
      const item = newState.items.find((i) => i.id === itemId);

      box.items = box.items.filter((item) => item.id !== itemId);

      return newState;
    }

    default:
      return state;
  }
}

export const ShipmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shipmentReducer, {
    items: [],
    boxes: [],
    sender: {},
    recipient: {},
  });

  const initialize = (items, boxes, sender, recipient) => {
    dispatch({ type: "INITIALIZE", payload: { items, boxes, sender, recipient } });
  };

  const moveItem = (fromBoxId, toBoxId, itemId, quantity) => {
    dispatch({ type: "MOVE_ITEM", payload: { fromBoxId, toBoxId, itemId, quantity } });
  };

  const addNewBox = () => {
    dispatch({ type: "ADD_NEW_BOX" });
  };

  const mergeBoxes = (fromBoxId, toBoxId) => {
    dispatch({ type: "MERGE_BOXES", payload: { fromBoxId, toBoxId } });
  };

  const unpackBox = (boxId) => {
    dispatch({ type: "UNPACK_BOX", payload: { boxId } });
  };

  const unpackItem = (boxId, itemId) => {
    dispatch({ type: "UNPACK_ITEM", payload: { boxId, itemId } });
  };

  const updateBoxDimensions = (boxId, dimensions) => {
    dispatch({ type: "UPDATE_BOX_DIMENSIONS", payload: { boxId, dimensions } });
  };

  const updateBoxWeight = (boxId, weight) => {
    dispatch({ type: "UPDATE_BOX_WEIGHT", payload: { boxId, weight } });
  };

  return (
    <ShipmentContext.Provider
      value={{
        state,
        initialize,
        moveItem,
        addNewBox,
        mergeBoxes,
        unpackBox,
        unpackItem,
        updateBoxDimensions,
        updateBoxWeight,
      }}
    >
      {children}
    </ShipmentContext.Provider>
  );
};

export const useShipment = () => useContext(ShipmentContext);
