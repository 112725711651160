import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AXIOS_INSTANCE } from "../api/partbot/axios-instance";
import { InternalAxiosRequestConfig } from "axios";
type Dispatch = (Auth: string) => void;

type AuthProviderProps = { children: ReactNode; initialState?: string | null };

const AuthContext = createContext<string | null>(null);
const AuthDispatchContext = createContext<Dispatch | null>(null);

const AuthProvider = ({ children, initialState = null }: AuthProviderProps) => {
  // it's a quick demo with useState but you can also have a more complexe state with a useReducer
  const [token, setToken] = useState(initialState);
  const [isLoaded, setIsLoaded] = useState(false);

  // intercept axios request to add the token in the header
  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use(
      (config) => {
        config.headers = Object.assign(
          {
            Authorization: `Bearer ${token}`,
          },
          config.headers
        );
        return config;
      },
      null,
      { synchronous: true }
    );

    setIsLoaded(true);

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [token]);

  return isLoaded ? (
    <AuthContext.Provider value={token}>
      <AuthDispatchContext.Provider value={setToken}>{children}</AuthDispatchContext.Provider>
    </AuthContext.Provider>
  ) : null;
};

const useAuth = (): string | null => {
  return useContext<string | null>(AuthContext);
};

const useAuthDispatch = (): Dispatch => {
  const context = useContext<Dispatch | null>(AuthDispatchContext);

  if (context === null) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth, useAuthDispatch };
