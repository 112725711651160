import React, { lazy, Suspense, useState, useEffect, Fragment } from "react";
import {
  useParams,
  useSearchParams,
  Link as RouterLink,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  getShipmentsListQueryOptions,
  useShipmentsList,
} from "../../../../api/partbot/shipments/shipments";

import { Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";

export const loader =
  (queryClient) =>
  async ({ params, request }) => {
    const url = new URL(request.url);
    const page = url.searchParams.get("page") || "1";
    const page_size = url.searchParams.get("page_size") || "1";
    return await queryClient.ensureQueryData(
      getShipmentsListQueryOptions({
        location_ids: [params.locationId],
        page: page,
        page_size: page_size,
      })
    );
  };

export default function Bookings() {
  const { locationId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    data: { results: shipments, pagination },
    refetch,
  } = useShipmentsList({
    location_ids: [locationId],
    page: searchParams.get("page"),
    page_size: searchParams.get("page_size"),
  });

  console.log("Bookings", shipments, pagination);

  return (
    <div className="space-y-5">
      <BookingCards bookings={shipments} locationId={locationId} />
      {shipments?.length >= 1 && <PaginationComponent pagination={pagination} />}
    </div>
  );
}

const ShipmentCard = ({ booking, locationId, onCheckboxChange }) => {
  console.log("ShipmentCard", booking);
  const [loading, setLoading] = useState({ label: false, connote: false });

  const handleDownload = async (documentType, trackingNumber, fileNameSuffix) => {
    setLoading((prev) => ({ ...prev, [fileNameSuffix]: true }));
    try {
      const file = await bookingDocument(locationId, booking.id, { document_type: documentType });
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${trackingNumber}_${fileNameSuffix}.pdf`);
      document.body.appendChild(link);
      link.click();
    } finally {
      setLoading((prev) => ({ ...prev, [fileNameSuffix]: false }));
    }
  };

  const totalWeight = 0;
  const dangerousGoods = "Y"; // Assuming there's a dangerous_goods field

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const statusClass = (status) => {
    switch (status?.toLowerCase()) {
      case "booked":
        return "bg-green-100 text-green-800";
      case "in transit":
        return "bg-blue-100 text-blue-800";
      case "delivered":
        return "bg-gray-100 text-gray-800";
      default:
        return "bg-yellow-100 text-yellow-800";
    }
  };

  return (
    <div className="relative mb-4 flex items-start justify-between rounded-lg border border-gray-200 p-4 text-sm shadow-sm">
      <input
        type="checkbox"
        onChange={(e) => onCheckboxChange(booking.id, e.target.checked)}
        className="mr-2"
      />
      <div className="flex-1">
        <div className="font-semibold text-gray-800">{booking.identifiers.join(", ")}</div>
        <div className="text-gray-500">{booking.friendly_id}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Origin</div>
        <div className="text-gray-600">
          {booking.sender.city} {booking.sender.subdivision_code} {booking.sender.postal_code},{" "}
          {booking.sender.country_code}
        </div>
        <div className="text-gray-500">{booking.sender.name}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Destination</div>
        <div className="text-gray-600">
          {booking.recipient?.city} {booking.recipient.subdivision_code}{" "}
          {booking.recipient.postal_code}, {booking.recipient.country_code}
        </div>
        <div className="text-gray-500">{booking.recipient.name}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Carrier</div>
        <div className="text-gray-600">{}</div>
        <div className="text-gray-500">{}</div>
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Details</div>
        <div className="text-gray-600">Items: {2}</div>
        <div className="text-gray-600">Weight: {totalWeight} kg</div>
        {dangerousGoods && <div className="font-semibold text-red-500">Dangerous Goods</div>}
      </div>
      <div className="flex-1">
        <div className="font-semibold text-gray-800">Dates</div>
        <div className="text-gray-600">Created: {formatDate(booking.created_at)}</div>
        <div className="text-gray-600">
          Pickup: {formatDate(booking.expected_pickup_dates?.earliest)} -{" "}
          {formatDate(booking.expected_pickup_dates?.latest)}
        </div>
        <div className="text-gray-600">
          Delivery: {formatDate(booking.expected_delivery_dates?.earliest)} -{" "}
          {formatDate(booking.expected_delivery_dates?.latest)}
        </div>
      </div>
      <div className="flex-1 text-right">
        <div as="div" className="relative inline-block text-left text-xs">
          <div>
            <RouterLink to={`/locations/${locationId}/shipments/${booking.id}`}>
              <button className="inline-flex w-full justify-center rounded-md border border-indigo-300 bg-indigo-500 px-3 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                Prepare
                <ChevronRightIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            </RouterLink>
          </div>
        </div>
      </div>
      <div className="absolute bottom-4 left-4">
        <span
          className={`inline-block rounded px-2 py-1 text-xs font-semibold capitalize ${statusClass(
            booking.status
          )}`}
        >
          {booking.status}
        </span>
      </div>
    </div>
  );
};
const BookingCards = ({ bookings, locationId }) => {
  const [selectedBookings, setSelectedBookings] = useState([]);

  const handleCheckboxChange = (id, checked) => {
    setSelectedBookings((prev) => {
      if (checked) {
        return [...prev, id];
      } else {
        return prev.filter((bookingId) => bookingId !== id);
      }
    });
  };

  const handleBulkAction = (actionType) => {
    // Implement the bulk action logic here
    console.log(`Performing ${actionType} on: `, selectedBookings);
  };

  return (
    <div>
      {/* <div className="mb-4">
        <button
          className="mr-2 rounded bg-indigo-500 px-2 py-1.5 text-xs text-white hover:bg-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-300"
          disabled={selectedBookings.length === 0}
          onClick={() => handleBulkAction("manifest")}
        >
          Manifest {selectedBookings.length}{" "}
          {selectedBookings.length > 1 || selectedBookings.length == 0 ? "Bookings" : "Booking"}
        </button>
      </div> */}
      {bookings?.map((booking) => (
        <ShipmentCard
          key={booking.id}
          booking={booking}
          locationId={locationId}
          onCheckboxChange={handleCheckboxChange}
        />
      ))}
    </div>
  );
};

const PaginationComponent = ({ pagination }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentPage = parseInt(searchParams.get("page")) || 1;
  const storedPageSize = localStorage.getItem("page_size") || pagination.page_size || "10";
  const [pageSize, setPageSize] = useState(storedPageSize);

  useEffect(() => {
    localStorage.setItem("page_size", pageSize);
  }, [pageSize]);

  const handlePageChange = (newPage) => {
    navigate(`?page=${newPage}&page_size=${pageSize}`);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    navigate(`?page=1&page_size=${e.target.value}`);
  };

  return (
    <div className="mx-auto max-w-2xl p-4">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <label htmlFor="page_size" className="text-sm font-medium text-gray-700">
            Page size:
          </label>
          <select
            id="page_size"
            value={pageSize}
            onChange={handlePageSizeChange}
            className="rounded border-gray-300 text-gray-700 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="rounded bg-indigo-500 px-4 py-2 text-white hover:bg-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-300"
            disabled={!pagination.previous_page_allowed}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <span className="text-sm font-medium text-gray-700">{`Page ${pagination.current_page} of ${pagination.total_pages}`}</span>
          <button
            className="rounded bg-indigo-500 px-4 py-2 text-white hover:bg-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-300"
            disabled={!pagination.next_page_allowed}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const Spinner = () => (
  <svg
    className="ml-4 h-3 w-3 animate-spin text-indigo-700"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 2.686 8.165 6.553 9.747l1.447-2.456z"
    ></path>
  </svg>
);
