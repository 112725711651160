/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Partbot Distributor API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Deliveries,
  DeliveriesListParams,
  DeliveriesPickup200,
  DeliveriesPickupBody,
  DeliveryDocumentParams
} from '.././models'
import { customInstance } from '.././axios-instance';
import type { ErrorType } from '.././axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



/**
 * List all deliveries for one or more locations
 * @summary List Deliveries
 */
export const deliveriesList = (
    params?: DeliveriesListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Deliveries[]>(
      {url: `/deliveries`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDeliveriesListQueryKey = (params?: DeliveriesListParams,) => {
    return [`/deliveries`, ...(params ? [params]: [])] as const;
    }

    
export const getDeliveriesListQueryOptions = <TData = Awaited<ReturnType<typeof deliveriesList>>, TError = ErrorType<unknown>>(params?: DeliveriesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof deliveriesList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeliveriesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof deliveriesList>>> = ({ signal }) => deliveriesList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof deliveriesList>>, TError, TData> & { queryKey: QueryKey }
}

export type DeliveriesListQueryResult = NonNullable<Awaited<ReturnType<typeof deliveriesList>>>
export type DeliveriesListQueryError = ErrorType<unknown>

/**
 * @summary List Deliveries
 */
export const useDeliveriesList = <TData = Awaited<ReturnType<typeof deliveriesList>>, TError = ErrorType<unknown>>(
 params?: DeliveriesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof deliveriesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeliveriesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a delivery pickup
 * @summary Create Deliveries Pickup
 */
export const deliveriesPickup = (
    deliveriesPickupBody: DeliveriesPickupBody,
 ) => {
      
      
      return customInstance<DeliveriesPickup200>(
      {url: `/deliveries/pickup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliveriesPickupBody
    },
      );
    }
  


export const getDeliveriesPickupMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deliveriesPickup>>, TError,{data: DeliveriesPickupBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deliveriesPickup>>, TError,{data: DeliveriesPickupBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deliveriesPickup>>, {data: DeliveriesPickupBody}> = (props) => {
          const {data} = props ?? {};

          return  deliveriesPickup(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeliveriesPickupMutationResult = NonNullable<Awaited<ReturnType<typeof deliveriesPickup>>>
    export type DeliveriesPickupMutationBody = DeliveriesPickupBody
    export type DeliveriesPickupMutationError = ErrorType<unknown>

    /**
 * @summary Create Deliveries Pickup
 */
export const useDeliveriesPickup = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deliveriesPickup>>, TError,{data: DeliveriesPickupBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deliveriesPickup>>,
        TError,
        {data: DeliveriesPickupBody},
        TContext
      > => {

      const mutationOptions = getDeliveriesPickupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a delivery document
 * @summary Get Delivery Document
 */
export const deliveryDocument = (
    id: number,
    params: DeliveryDocumentParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Blob>(
      {url: `/deliveries/${id}/documents`, method: 'GET',
        params,
        responseType: 'blob', signal
    },
      );
    }
  

export const getDeliveryDocumentQueryKey = (id: number,
    params: DeliveryDocumentParams,) => {
    return [`/deliveries/${id}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getDeliveryDocumentQueryOptions = <TData = Awaited<ReturnType<typeof deliveryDocument>>, TError = ErrorType<unknown>>(id: number,
    params: DeliveryDocumentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof deliveryDocument>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeliveryDocumentQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof deliveryDocument>>> = ({ signal }) => deliveryDocument(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof deliveryDocument>>, TError, TData> & { queryKey: QueryKey }
}

export type DeliveryDocumentQueryResult = NonNullable<Awaited<ReturnType<typeof deliveryDocument>>>
export type DeliveryDocumentQueryError = ErrorType<unknown>

/**
 * @summary Get Delivery Document
 */
export const useDeliveryDocument = <TData = Awaited<ReturnType<typeof deliveryDocument>>, TError = ErrorType<unknown>>(
 id: number,
    params: DeliveryDocumentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof deliveryDocument>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeliveryDocumentQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



