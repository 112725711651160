/* This example requires Tailwind CSS v2.0+ */
import { Switch } from "@headlessui/react";
import React, { useState, useCallback } from "react";
import { classNames } from "../../../utils";
import clsx from "clsx";
import { get } from "react-hook-form";

export default function Toggle({
  checked,
  label,
  listingId,
  onChangeCallback,
  disabled,
  showLabel = false,
  size = "md",
}) {
  const [enabled, setEnabled] = useState(checked);

  const onToggle = useCallback(() => {
    setEnabled(!enabled);
    if (onChangeCallback) {
      onChangeCallback(!enabled);
    }
  }, [enabled]);

  const getSwitchSize = () => {
    switch (size) {
      case "sm":
        return "h-4 w-7";
      case "md":
        return "h-6 w-11";
      case "lg":
        return "h-8 w-14";
      default:
        return "h-6 w-11";
    }
  };

  const getControlSize = () => {
    switch (size) {
      case "sm":
        return "h-3 w-3";
      case "md":
        return "h-5 w-5";
      case "lg":
        return "h-7 w-7";
      default:
        return "h-5 w-5";
    }
  };

  return (
    <Switch
      checked={enabled}
      onChange={onToggle}
      disabled={disabled}
      className={clsx(
        enabled ? "bg-indigo-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:pointer-events-none  disabled:opacity-20",
        getSwitchSize(size)
      )}
    >
      <span className={!showLabel && "sr-only"}>{label}</span>
      <span
        aria-hidden="true"
        className={clsx(
          enabled ? "translate-x-full" : "translate-x-0",
          "pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
          getControlSize(size)
        )}
      />
    </Switch>
  );
}
