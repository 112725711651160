import { faBox, faSync } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../UI/components";
import { NotificationBox } from "./components/NotificationBox";
import { StoreSelect } from "./components/MerchantProducts/StoreSelect";
import { Header } from "./components/Header";
import { DetailsModal } from "./components/MerchantProducts/DetailsModal";
import { Hits } from "./components/MerchantProducts/Hits";
import { SearchBar } from "./components/MerchantProducts/SearchBar";
import { SearchStats } from "./components/MerchantProducts/SearchStats";
import { Pagination } from "./components/Pagination";
import { Sidebar } from "./components/Sidebar";
import { useInterval } from "./hooks/useInterval";
import { useMarketplaceProductState } from "./hooks/useMarketplaceProductState";
import { MarketplaceProductStore } from "./state/MarketplaceProductStore";
import { StoreProvider } from "./state/StoreProvider";
import { ViewLayoutSelect } from "./components/MerchantProducts/ViewLayoutSelect";
import { JobProgressBar } from "./components/JobProgressBar";
import { BrandSelect } from "./components/MerchantProducts/BrandSelect";
import { StatusSelect } from "./components/MerchantProducts/StatusSelect";

const formatProductsError = (errors) => {
  return errors.map((error) => {
    if (error.code === "unauthorized_access") {
      return {
        message: `Your store ${error.shop_name} is not authorized to sync. Please reconnect your store.`,
        code: error.code,
      };
    }

    return error;
  });
};

const PROGRESS_SYNC_PROGRESS_INTERVAL = 5000;

const EmptyState = ({ loading, onClick }) => {
  return (
    <div className="text-center">
      <FontAwesomeIcon
        icon={faBox}
        className="w-4 text-center text-gray-400 transition group-hover:text-indigo-800"
      />
      <h3 className="mt-2 text-sm font-medium text-gray-900">No products</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by syncing all products from connected stores.
      </p>
      <div className="mt-6">
        <Button
          icon={faSync}
          showLoading={loading}
          onClick={onClick}
          label={"Sync All"}
          disabled={loading}
        />
      </div>
    </div>
  );
};

const MarketplaceProducts = ({ app_contexts, brands }) => {
  const {
    importListingCount,
    team,
    totalRows,
    totalHits,
    currentPage,
    perPage,
    fetchProducts,
    searchQuery,
    productsLoading,
    productsError,
    syncAllProducts,
    syncProgress,
    syncTotal,
    syncAt,
    fetchSyncProgress,
    cancelSyncAllProducts,
    resetSyncProgress,
    stores,
  } = useMarketplaceProductState();

  const handleSyncAll = async () => {
    const response = await syncAllProducts();
    if (response?.status === 200) {
      fetchSyncProgress();
      setIsFetchingSyncProgress(true);
    }
  };

  useEffect(async () => {
    const response = await fetchProducts(
      currentPage,
      perPage,
      searchQuery,
      selectedStore,
      selectedBrand,
      selectedStatus,
      selectedPublishedLevel
    );
    if (response.status === 200) {
      if (response?.data?.meta?.sync_job_ids?.length > 0) {
        fetchSyncProgress();
        setIsFetchingSyncProgress(true);
      }
    }
  }, []);

  const scrollRef = useRef(null);
  const scrollToTop = () => scrollRef.current.scrollIntoView();
  const [isFetchingSyncProgress, setIsFetchingSyncProgress] = useState(false);

  const storedView = localStorage.getItem("products_view_setting");
  const [currentView, setCurrentView] = useState(storedView || "grid");

  const handleSearch = async (query) => {
    const response = await fetchProducts(
      1,
      perPage,
      query,
      selectedStore,
      selectedBrand,
      selectedStatus,
      selectedPublishedLevel
    );
    if (response?.status === 200) {
      scrollToTop();
    }
  };

  const [selectedStore, setSelectedStore] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPublishedLevel, setSelectedPublishedLevel] = useState(true);
  const [selectedPage, setSelectedPage] = useState("");

  // create a useEffect that runs once to parse the query string and set the selected store, brand, and status
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const store = query.get("store");
    const brand = query.get("brand");
    const status = query.get("status");
    const page = query.get("page");
    const published = query.get("published");

    // add a delay before processing the query string so that the state is set
    setTimeout(() => {
      if (store) {
        setSelectedStore(store);
      }
      if (brand) {
        setSelectedBrand(brand);
      }
      if (status) {
        setSelectedStatus(status);
      }
      if (page) {
        setSelectedPage(page);
      }
      if (published) {
        setSelectedPublishedLevel(published);
      }
    }, 500);
  }, []);

  // create an async useEffect that fetches products with the selected store, brand, page and status when changed
  useEffect(async () => {
    const query = new URLSearchParams(window.location.search);
    if (selectedStore) {
      query.set("store", selectedStore);
    }
    if (selectedBrand) {
      query.set("brand", selectedBrand);
    }
    if (selectedStatus) {
      query.set("status", selectedStatus);
    }
    if (selectedPublishedLevel) {
      query.set("published", selectedPublishedLevel);
    }
    if (selectedPage) {
      query.set("page", selectedPage);
    }
    if (!selectedStore) {
      query.delete("store");
    }
    if (!selectedBrand) {
      query.delete("brand");
    }
    if (!selectedStatus) {
      query.delete("status");
    }
    if (!selectedPublishedLevel) {
      query.delete("published");
    }
    if (!selectedPage) {
      query.delete("page");
    }
    window.history.pushState({}, "", `?${query.toString()}`);

    const response = await fetchProducts(
      selectedPage,
      perPage,
      searchQuery,
      selectedStore,
      selectedBrand,
      selectedStatus,
      selectedPublishedLevel
    );
    if (response?.status === 200) {
      scrollToTop();
    }
  }, [selectedStore, selectedBrand, selectedStatus, selectedPage, selectedPublishedLevel]);

  useInterval(
    async () => {
      const response = await fetchSyncProgress();
      if (response?.status === 200) {
        if (response?.data?.progress == null) {
          setIsFetchingSyncProgress(false);
        }
        const progress = response?.data?.progress;
        if (progress <= 100) {
          const response = await fetchProducts(
            1,
            perPage,
            searchQuery,
            selectedStore,
            selectedBrand,
            selectedStatus,
            selectedPublishedLevel
          );

          if (response?.status === 200) {
            if (progress === 100) {
              scrollToTop();
              setIsFetchingSyncProgress(false);
              resetSyncProgress();
            }
          }
        }
      } else {
        setIsFetchingSyncProgress(false);
      }
    },
    isFetchingSyncProgress ? PROGRESS_SYNC_PROGRESS_INTERVAL : null
  );

  const handleCancelSyncAll = async () => {
    const response = await cancelSyncAllProducts();
    if (response?.status === 200) {
      const response = await fetchProducts(
        1,
        perPage,
        searchQuery,
        selectedStore,
        selectedBrand,
        selectedStatus,
        selectedPublishedLevel
      );
      if (response?.status === 200) {
        scrollToTop();
        setIsFetchingSyncProgress(false);
        resetSyncProgress();
      }
    }
  };

  const isSyncing = syncProgress !== null && syncProgress !== undefined;

  const switchView = (view) => {
    // store view in local storage
    localStorage.setItem("products_view_setting", view);
    setCurrentView(view);
  };

  return (
    <div ref={scrollRef}>
      <Header section="Products" app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar
              team={team}
              importListCount={importListingCount}
              merchantProductsCount={totalRows}
            />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <div className="flex w-full items-center justify-between">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">My Products</h2>
              <div title="bulk actions">
                {totalRows > 0 && (
                  <Button
                    icon={faSync}
                    showLoading={isSyncing}
                    onClick={handleSyncAll}
                    label={"Sync All"}
                    disabled={isSyncing}
                  />
                )}
              </div>
            </div>

            <section>
              {totalRows > 0 && (
                <div className="flex w-full space-x-3 rounded-lg bg-white p-3 shadow">
                  <div className="relative flex sm:w-1/2 lg:w-2/5">
                    <SearchBar value={searchQuery} onSubmit={handleSearch} />
                  </div>
                  {stores && stores.length > 1 && (
                    <div className="relative flex-shrink-0">
                      <StoreSelect
                        defaultValue={selectedStore}
                        stores={stores}
                        onSubmit={(value) => {
                          setSelectedStore(value);
                          setSelectedPage(1);
                        }}
                      />
                    </div>
                  )}
                  <div className="flex-shrink-0">
                    <BrandSelect
                      defaultValue={selectedBrand}
                      brands={brands}
                      onSubmit={(value) => {
                        setSelectedBrand(value);
                        setSelectedPage(1);
                      }}
                    />
                  </div>
                  <div className="flex-shrink-0">
                    <StatusSelect
                      defaultValue={selectedStatus}
                      statuses={[
                        {
                          value: 0,
                          label: "Synced",
                        },
                        {
                          value: 1,
                          label: "Linked",
                        },
                        {
                          value: 2,
                          label: "Not Linked",
                        },
                      ]}
                      onSubmit={(value) => {
                        setSelectedStatus(value);
                        setSelectedPage(1);
                      }}
                    />
                  </div>
                  <div className="relative flex-shrink-0">
                    <StatusSelect
                      defaultValue={selectedPublishedLevel}
                      deselectedLabel="All Products"
                      statuses={[
                        {
                          value: true,
                          label: "Published",
                        },
                        {
                          value: false,
                          label: "Draft / Archived",
                        },
                      ]}
                      onSubmit={(value) => {
                        console.log("selectedPublishedLevel", value);
                        setSelectedPublishedLevel(value);
                        setSelectedPage(1);
                      }}
                    />
                  </div>
                  <div className="relative flex-shrink-0">
                    <ViewLayoutSelect onChange={switchView} selectedView={currentView} />
                  </div>
                </div>
              )}
              <div className="mt-4">
                {totalHits > 0 && <SearchStats nbHits={totalHits} loading={productsLoading} />}
                {isSyncing && (
                  <JobProgressBar
                    progress={syncProgress}
                    total={syncTotal}
                    at={syncAt}
                    onCancel={handleCancelSyncAll}
                    actionName="Syncing"
                    actionPast="processed"
                  />
                )}
              </div>
              <Hits view={currentView} searchQuery={searchQuery} />
            </section>

            {productsError && (
              <NotificationBox notifications={formatProductsError(productsError)} />
            )}

            {totalRows === 0 && !productsLoading && (
              <EmptyState loading={isSyncing} onClick={handleSyncAll} />
            )}

            {totalHits === 0 && totalRows !== 0 && !productsLoading && <div>No products found</div>}

            <section>
              <DetailsModal />
            </section>

            <section>
              {totalHits > perPage && (
                <div className="pb-4">
                  <Pagination
                    currentPage={currentPage}
                    totalRows={totalHits}
                    perPage={perPage}
                    onChangePage={(page) => setSelectedPage(page)}
                  />
                </div>
              )}
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

const MarketplaceProductsContainer = ({ children, ...props }) => {
  const store = MarketplaceProductStore({
    apiKey: props.api_key,
    location: location,
    importListingCount: props.import_listings_count,
    totalRows: props.merchant_products_count,
    team: props.team,
  });

  return (
    <StoreProvider store={store}>
      <MarketplaceProducts {...props}>{children}</MarketplaceProducts>
    </StoreProvider>
  );
};

export default MarketplaceProductsContainer;
