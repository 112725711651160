import "trix/dist/trix.css";

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);

import "../stylesheets/application.css";

import ReactOnRails from "react-on-rails";
import { default as DistributorApp } from "../../apps/distributor/app";
import SearchBar from "../../bundles/UI/components/SearchBar";
import AppSwitch from "../../bundles/UI/components/AppSwitch";
import BrandSwitch from "../../bundles/UI/components/BrandSwitch";
import DataExport from "../../bundles/DataExport/components/DataExport";
import ExportTemplateEditor from "../../bundles/ExportTemplates/ExportTemplateEditor";
import ExportTemplateList from "../../bundles/ExportTemplates/ExportTemplateList";
import ExternalVehicleList from "../../bundles/ExternalVehicles/components/ExternalVehicleList";
import ExternalVehicleTranslationCard from "../../bundles/ExternalVehicles/components/ExternalVehicleTranslationCard";
import ExternalVehicleLinkingView from "../../bundles/ExternalVehicles/components/ExternalVehicleLinkingView";
import ExternalVehicleTranslation from "../../bundles/ExternalVehicles/components/ExternalVehicleTranslation";
import ProductsTable from "../../bundles/Products/components/ProductsTable";
import ProductIssuesTable from "../../bundles/Products/components/ProductIssuesTable";
import ProductActivityTable from "../../bundles/Products/components/ProductActivityTable";
import ProductEditor from "../../bundles/Products/components/ProductEditor";
import CrossSellProductEditor from "../../bundles/Products/components/CrossSellProductEditor";
import CrossSellProductList from "../../bundles/Products/components/CrossSellProductList";
import ProductImport from "../../bundles/Products/components/ProductImport";
import ShopifyListingsTable from "../../bundles/ShopifyListing/components/ShopifyListingsTable";
import ShopifyListing from "../../bundles/ShopifyListing/components/ShopifyListing";
import VehiclesTable from "../../bundles/Vehicles/components/VehiclesTable";
// import WarehouseEditor from "../bundles/Warehouse/components/WarehouseEditor";
import ShopifyFulfillmentsTable from "../../bundles/Fulfillments/components/ShopifyFulfillmentsTable";
import FulfillmentOrdersTable from "../../bundles/Fulfillments/components/FulfillmentOrdersTable";
import FulfillmentOrder from "../../bundles/Marketplace/FulfillmentOrder";
import AccountSettings from "../../bundles/Account/AccountSettings";

import AmazonListings from "../../bundles/AmazonListing/AmazonListings";
import { Header } from "../../bundles/Marketplace/components/Header";
import MarketplaceSignup from "../../bundles/Marketplace/MarketplaceSignup";
import MarketplaceDashboard from "../../bundles/Marketplace/MarketplaceDashboard";
import MarketplaceImport from "../../bundles/Marketplace/MarketplaceImport";
import MarketplaceBilling from "../../bundles/Marketplace/MarketplaceBilling";
import MarketplaceAccount from "../../bundles/Marketplace/MarketplaceAccount";
import MarketplaceSettings from "../../bundles/Marketplace/MarketplaceSettings";
import MarketplaceDistributors from "../../bundles/Marketplace/MarketplaceDistributors";
import MarketplaceProducts from "../../bundles/Marketplace/MarketplaceProducts";
import BrandEditor from "../../bundles/Brands/BrandEditor";
import BrandTemplates from "../../bundles/Brands/BrandTemplates";
import VehicleForm from "../../bundles/Vehicles/VehicleForm";
import ExportTemplateFieldsEditor from "../../bundles/ExportTemplates/ExportTemplateFieldsEditor";
import { GettingStartedModal } from "../../bundles/Marketplace/components/GettingStartedModal";
import { MaropostWarehouseCombobox } from "../../bundles/Marketplace/components/MaropostWarehouseCombobox";

// Distributor
import DistributorDashboard from "../../bundles/Distributors/DistributorDashboard";
import DistributorLocations from "../../bundles/Distributors/DistributorLocations";
import DistributorFulfillments from "../../bundles/Distributors/DistributorFulfillments";
import DistributorShipments from "../../bundles/Distributors/DistributorShipments";
import DistributorHandovers from "../../bundles/Distributors/DistributorHandovers";
import ManageDistributorFulfillment from "../../bundles/Distributors/ManageDistributorFulfillment";
import DistributorCapture from "../../bundles/Distributors/DistributorCapture";
import DistributorManualShipment from "../../bundles/Distributors/DistributorManualShipment";
import LabelRequests from "../../bundles/Distributors/LabelRequests";
import DistributorSettings from "../../bundles/Distributors/DistributorSettings";
import DistributorInventory from "../../bundles/Distributors/DistributorInventory";
import DistributorPricing from "../../bundles/Distributors/DistributorPricing";
import DistributorPayments from "../../bundles/Distributors/DistributorPayments";

import ReindexProducts from "../../bundles/Products/components/ReindexProducts";

ReactOnRails.register({
  DistributorApp,
  SearchBar,
  AppSwitch,
  BrandSwitch,
  DataExport,
  ExternalVehicleList,
  ExternalVehicleTranslation,
  ExternalVehicleTranslationCard,
  ExternalVehicleLinkingView,
  ProductsTable,
  ProductIssuesTable,
  ProductActivityTable,
  ProductEditor,
  CrossSellProductEditor,
  CrossSellProductList,
  ProductImport,
  ShopifyListingsTable,
  ShopifyListing,
  VehiclesTable,
  VehicleForm,
  ShopifyFulfillmentsTable,
  FulfillmentOrdersTable,
  FulfillmentOrder,
  AmazonListings,
  AccountSettings,
  Header,
  MarketplaceSignup,
  MarketplaceDashboard,
  MarketplaceImport,
  MarketplaceBilling,
  MarketplaceAccount,
  MarketplaceSettings,
  MarketplaceProducts,
  MarketplaceDistributors,
  BrandEditor,
  BrandTemplates,
  DistributorDashboard,
  DistributorLocations,
  DistributorFulfillments,
  DistributorShipments,
  DistributorHandovers,
  DistributorManualShipment,
  LabelRequests,
  ManageDistributorFulfillment,
  DistributorCapture,
  DistributorSettings,
  DistributorInventory,
  DistributorPricing,
  DistributorPayments,
  ExportTemplateEditor,
  ExportTemplateList,
  ExportTemplateFieldsEditor,
  GettingStartedModal,
  MaropostWarehouseCombobox,
  ReindexProducts,
});

const tabSystem = {
  init() {
    document.querySelectorAll(".tabs-menu").forEach((tabMenu) => {
      Array.from(tabMenu.children).forEach((child, ind) => {
        console.log("tab", child);
        child.addEventListener("click", () => {
          console.log("tab clicked", child.dataset.target);
          tabSystem.toggle(child.dataset.target);
        });
        if (child.className.includes("is-active")) {
          tabSystem.toggle(child.dataset.target);
        }
      });
    });
  },
  toggle(targetId) {
    document.querySelectorAll(".tab-pane").forEach((contentElement) => {
      console.log("toggle", contentElement);
      contentElement.style.display = contentElement.id === targetId ? "block" : "none";

      const tabLink = document.querySelector(`[data-target="${contentElement.id}"]`);

      if (tabLink) {
        tabLink.classList[contentElement.id === targetId ? "add" : "remove"]("is-active");
      }
    });
  },
};

document.addEventListener("DOMContentLoaded", function () {
  tabSystem.init();

  document.querySelectorAll("form.button_to").forEach((form) => {
    form.addEventListener("submit", confirmSubmission);
  });
});

function toggleModal(modalID) {
  document.getElementById(modalID).classList.toggle("hidden");
  document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
  document.getElementById(modalID).classList.toggle("flex");
  document.getElementById(modalID + "-backdrop").classList.toggle("flex");
}

function confirmSubmission(event) {
  const button = event.target.querySelector("[data-confirm]");
  const message = button?.dataset?.confirm;

  return new Promise((resolve) => {
    if (!message || confirm(message)) {
      resolve();
    } else {
      event.detail.formSubmission.stop();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });
}
