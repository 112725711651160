import React from "react";
import { useShipmentById } from "../../../../api/partbot/shipments/shipments";
import { useParams } from "react-router-dom";

export const ShipmentDataLoader = ({ children }) => {
  const { shipmentId } = useParams();
  const { data, error, isLoading } = useShipmentById(shipmentId);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  const items = data.units.map((unit) => ({
    id: unit.id,
    name: unit.label,
    quantity: unit.quantity,
    weight: unit.weight_g / 1000,
    dimensions: {
      length: unit.length_mm / 10,
      width: unit.width_mm / 10,
      height: unit.height_mm / 10,
    },
  }));

  const boxes = data.cartons.map((carton) => ({
    id: carton.id,
    dimensions: {
      length: carton.length_mm / 10,
      width: carton.width_mm / 10,
      height: carton.height_mm / 10,
    },
    weight: carton.weight_g / 1000,
    items: carton.carton_items.map((item) => ({
      id: item.shipment_unit_id,
      quantity: item.quantity,
    })),
  }));

  const initialData = {
    items,
    boxes,
    sender: data.sender,
    recipient: data.recipient,
  };

  return children(initialData);
};
