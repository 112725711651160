import { faBoxOpen, faStoreAlt, faTruckFast } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import {
  faArrowUpFromDottedLine,
  faTimes,
  faCircleExclamation,
  faSpinnerThird,
  faDatabase,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import ProductTabs from "./ProductTabs";
import ProductIssueModal from "../../../Products/components/ProductIssueModal";
import { Button } from "../../../UI/components";
import { useEffect } from "react";
import ProductImage from "../../../UI/components/ProductImage";

export const ImportListModal = ({
  selectedProduct,
  addToImportList,
  setSelectedProduct,
  productData,
  issueResponseDays,
  isLoading = false,
}) => {
  const [data, setData] = useState(productData);
  const [products, setProducts] = useState([]);
  const [isProductIssueModalOpen, setIsProductIssueModalOpen] = useState(false);

  useEffect(() => {
    setData(productData || null);
    setProducts(productData?.products?.sort((a, b) => a.cost - b.cost) || []);
    return () => {
      setProducts([]);
    };
  }, [productData]);

  const onCloseModal = async () => {
    setIsProductIssueModalOpen(false);
    setSelectedProduct(null);
    setProducts([]);
    setData(null);
  };

  return (
    <AnimatePresence>
      {selectedProduct && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed bottom-0 left-0 right-0 top-0 z-40 h-screen w-screen backdrop-blur-sm backdrop-brightness-90 md:px-[16vh] md:py-[8vh] xl:px-[24vh] xl:py-[12vh]"
        >
          <motion.div
            className="relative h-full w-full overflow-y-scroll rounded-lg bg-white px-8 pb-4 pt-8 shadow-xl"
            layoutId={selectedProduct.id}
          >
            <motion.div className="grid h-full w-full grid-cols-5 gap-6">
              <motion.div className="col-span-2 flex h-full flex-col space-y-6">
                <motion.div className="aspect-square">
                  <ProductImage
                    src={`https://res.cloudinary.com/proex/image/fetch/f_auto,w_1200,h_1200,c_pad,b_white,q_80,dpr_auto/${selectedProduct.image}`}
                    className="h-full w-full rounded-lg border border-gray-200 object-cover object-center p-6 lg:h-full lg:w-full"
                  />
                </motion.div>
                {products && products.length > 0 && (
                  <div className="relative w-full">
                    <button
                      className="btn !m-0 w-full"
                      onClick={() => {
                        addToImportList(selectedProduct.listing_id);
                        setSelectedProduct(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowUpFromDottedLine} />
                      <span>Add to Import List</span>
                    </button>
                  </div>
                )}
              </motion.div>
              <motion.div className="col-span-3 flex h-full w-full flex-col space-y-4 overflow-hidden">
                <div className="mt-1 text-xs font-bold uppercase tracking-wide text-gray-800">
                  <img
                    src={selectedProduct.brand_logo}
                    className="h-auto w-[120px]"
                    alt={selectedProduct.brand}
                  />
                </div>
                <motion.h5 className="pr-12 text-base font-extrabold tracking-tight text-gray-900">
                  {selectedProduct.title}
                </motion.h5>

                <div className="flex items-center space-x-2">
                  <div className="rounded bg-indigo-50 px-2 py-1 text-xs font-medium uppercase text-indigo-600">
                    RRP{products && products.length > 1 && " From"}{" "}
                    {!isLoading ? (
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "AUD",
                        currencyDisplay: "narrowSymbol",
                        minimumFractionDigits: 0,
                      }).format(selectedProduct.price)
                    ) : (
                      <FontAwesomeIcon
                        icon={faSpinnerThird}
                        className="ml-1 animate-spin text-xs"
                      />
                    )}
                  </div>

                  {products && products.length > 0 && (
                    <div className="bg-success-50 text-success-500 rounded px-2 py-1 text-xs font-medium uppercase">
                      Cost{products.length > 1 && " From"}{" "}
                      {!isLoading && products ? (
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "AUD",
                          currencyDisplay: "narrowSymbol",
                          minimumFractionDigits: 2,
                        }).format(products[0]?.cost)
                      ) : (
                        <FontAwesomeIcon
                          icon={faSpinnerThird}
                          className="ml-1 animate-spin text-xs"
                        />
                      )}
                    </div>
                  )}

                  <div className="rounded bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500">
                    Prices include GST
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-2 text-[10px] ">
                  <span className="space-x-2 rounded bg-purple-50 px-2 py-1 text-purple-600">
                    <FontAwesomeIcon icon={faStoreAlt} />
                    <span>{selectedProduct.stores | 0} Stores</span>
                  </span>
                  {selectedProduct.has_distribution ? (
                    <>
                      <span className="space-x-2 rounded bg-purple-50 px-2 py-1 text-purple-600">
                        <FontAwesomeIcon icon={faTruckFast} />
                        <span>{selectedProduct.orders | 0} Orders</span>
                      </span>
                      <span
                        className={`${
                          selectedProduct.inventory > 0
                            ? "bg-purple-50 text-purple-600"
                            : "bg-danger-50 text-danger-600"
                        } space-x-2 rounded px-2 py-1`}
                      >
                        <FontAwesomeIcon icon={faBoxOpen} />
                        <span>{selectedProduct.inventory | 0} Available</span>
                      </span>
                    </>
                  ) : (
                    <span
                      title="This product cannot be dropshipped"
                      className="text-warning-600 bg-warning-50 space-x-2 rounded px-2 py-1"
                    >
                      <FontAwesomeIcon icon={faDatabase} />
                      <span>Data only</span>
                    </span>
                  )}
                </div>
                {!isLoading && data && data.id ? (
                  <ProductTabs listing={data} />
                ) : !isLoading ? (
                  <div className="text-danger-700 bg-danger-100 w-full rounded-lg px-3 py-2 text-center text-sm">
                    Product data currently unavailable.
                  </div>
                ) : (
                  <div className="relative flex h-full w-full animate-pulse flex-col space-y-4 py-3">
                    <div className="flex gap-3 border-b border-gray-200 pb-3">
                      <div className="h-[28px] w-[75px] rounded bg-gray-200"></div>
                      <div className="h-[28px] w-[75px] rounded bg-gray-200"></div>
                      <div className="h-[28px] w-[75px] rounded bg-gray-200"></div>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center gap-3 rounded-md bg-gray-200 p-12 text-gray-400">
                      <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin text-3xl" />
                    </div>
                  </div>
                )}

                <motion.button
                  className="absolute right-3 top-0 flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-gray-500 hover:bg-gray-400 hover:text-gray-100"
                  onClick={() => setSelectedProduct(null)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </motion.button>
                {products && products.length > 0 && (
                  <div className="flex items-center justify-end border-t pb-1 pt-3">
                    <Button
                      label="Report Issue"
                      icon={faCircleExclamation}
                      className="btn btn-danger"
                      onClick={async () => {
                        await setIsProductIssueModalOpen(true);
                      }}
                    />
                  </div>
                )}
              </motion.div>
            </motion.div>
          </motion.div>
          <ProductIssueModal
            selectedProduct={selectedProduct}
            closeModal={onCloseModal}
            isOpen={isProductIssueModalOpen}
            issueResponseDays={issueResponseDays}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
