import React from "react";
import {
  getLocationsListQueryOptions,
  useLocationsList,
} from "../../../api/partbot/locations/locations";
import { useShipmentsStatistics } from "../../../api/partbot/shipments/shipments";
import { useFulfillmentsStatistics } from "../../../api/partbot/fulfillments/fulfillments";
import { faWarehouseFull } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { DashboardStatCard } from "../../../bundles/UI/components/DashboardStatCard";
import { Link as RouterLink } from "react-router-dom";

export const loader = (queryClient) => async () => {
  return await queryClient.ensureQueryData(getLocationsListQueryOptions());
};

export default function Dashboard() {
  const { data: distributorLocations, refetch } = useLocationsList();

  const distributorLocationsIds = distributorLocations?.map((location) => location.id);

  const { data: shipmentsStatistics, isFetching: shipmentsIsFetching } = useShipmentsStatistics(
    {
      location_ids: distributorLocationsIds,
    },
    {
      enabled: !!distributorLocationsIds,
    }
  );

  const { data: fulfillmentsStatistics, isFetching: fulfillmentsIsFetching } =
    useFulfillmentsStatistics(
      {
        location_ids: distributorLocationsIds,
      },
      {
        enabled: !!distributorLocationsIds,
      }
    );

  const isLoading = shipmentsIsFetching || fulfillmentsIsFetching;

  return (
    <div className="grid grid-cols-1 gap-5">
      {distributorLocations?.map((location) => {
        const locationShipmentsStatistics = shipmentsStatistics?.locations?.find(
          (locationStatistics) => locationStatistics.location_id === location.id
        );
        const locationFulfillmentsStatistics = fulfillmentsStatistics?.locations?.find(
          (locationStatistics) => locationStatistics.location_id === location.id
        );

        return (
          <div className="rounded-xl bg-white p-4 shadow-xl">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">{location.name}</h2>
              <span className="rounded bg-indigo-100 p-1 text-xs font-semibold uppercase text-indigo-600">
                {location.code}
              </span>
            </div>
            {/* {isLoading ? (
              <div className="mt-4 text-center">Loading...</div>
            ) : ( */}
            <div className="mt-4 grid grid-cols-4 gap-4">
              {/* /shipments?page=1&page_size=25  */}
              <RouterLink to={`/locations/${location.id}/shipments?page=1&page_size=25`}>
                <div className="rounded-md bg-gray-100 p-3 hover:ring-2 hover:ring-indigo-500">
                  <p className="text-sm text-gray-500">Total Shipments</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {locationShipmentsStatistics?.total}
                  </p>
                </div>
              </RouterLink>
              <RouterLink to={`/locations/${location.id}/fulfillments?page=1&page_size=25`}>
                <div className="rounded-md bg-gray-100 p-3 hover:ring-2 hover:ring-indigo-500">
                  <p className="text-sm text-gray-500">Total Fulfillments</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {locationFulfillmentsStatistics?.total}
                  </p>
                </div>
              </RouterLink>
            </div>
            {/* )} */}
          </div>
          // <DashboardStatCard
          //   key={location.id}
          //   title={
          //     <>
          //       <span className="mr-2">{location.name}</span>
          //       <span className="ml-2 rounded bg-indigo-100 p-1 text-xs font-semibold uppercase text-indigo-600">
          //         {location.code}
          //       </span>
          //     </>
          //   }
          //   link={`/locations/${location.id}/fulfillments`}
          //   label="View Fulfillments"
          //   icon={faWarehouseFull}
          //   count={locationStatistics?.total}
          // />
        );
      })}
    </div>
  );
}
