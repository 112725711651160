import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "../../utils/auth";
import { Outlet } from "react-router-dom";

// import Api from "./api";
import { Header } from "../../../bundles/Distributors/components/Header";
import { Sidebar } from "../../../bundles/Distributors/components/Sidebar";
import { DashboardStatCard } from "../UI/components/DashboardStatCard";
import { faWarehouseFull } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { useUser, useTeam } from "../../context";

import { useDistributorLocations } from "../../api/partbot/distributor-locations/distributor-locations";

function Layout({ api_key, distributor, app_contexts }) {
  // const { data: distributorLocations } = useDistributorLocations();
  const user = useUser();
  const team = useTeam();

  return (
    <>
      <Header app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <Outlet />
            {/* <div className="grid grid-cols-3 gap-5">
              {distributorLocations?.map((location) => (
                <DashboardStatCard
                  key={location.id}
                  title={
                    <>
                      <span className="mr-2">{location.name}</span>
                      <span className="ml-2 rounded bg-indigo-100 p-1 text-xs font-semibold uppercase text-indigo-600">
                        {location.code}
                      </span>
                    </>
                  }
                  link={`/l/${location.id}/fulfillments`}
                  label="View Fulfillments"
                  icon={faWarehouseFull}
                  count={location.fulfillments_count}
                />
              ))}

              {distributor.locations
                .filter((l) => l.active)
                .map((location) => (
                  <DashboardStatCard
                    key={location.id}
                    title={
                      <>
                        <span className="mr-2">{location.name}</span>
                        <span className="ml-2 rounded bg-indigo-100 p-1 text-xs font-semibold uppercase text-indigo-600">
                          {location.code}
                        </span>
                      </>
                    }
                    link={`/l/${location.id}/fulfillments`}
                    label="View Fulfillments"
                    icon={faWarehouseFull}
                    count={location.fulfillments_count}
                  />
                ))}
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Layout;
