import React, { useState, useEffect } from "react";

import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useDndMonitor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";

import { useParams } from "react-router-dom";

import { cn } from "../../../../utils/classNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamondExclamation } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";

import { ShipmentProvider, useShipment } from "./ShipmentProvider";

import { ShipmentDataLoader } from "./ShipmentDataLoader";

import {
  useShipmentUpdate,
  useShipmentDeliveryBooking,
} from "../../../../api/partbot/shipments/shipments";

export default function Shipment() {
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { delay: 250, tolerance: 5 },
  });
  const sensors = useSensors(mouseSensor, touchSensor);
  return (
    <ShipmentProvider>
      <ShipmentDataLoader>
        {(initialData) => (
          <DndContext sensors={sensors} collisionDetection={closestCenter}>
            <PlanningContent initialData={initialData} />
            <DragOverlayWrapper />
          </DndContext>
        )}
      </ShipmentDataLoader>
    </ShipmentProvider>
  );
}

const PlanningContent = ({ initialData }) => {
  const { state, initialize, moveItem, addNewBox, mergeBoxes, unpackBox, unpackItem } =
    useShipment();
  const { locationId, shipmentId } = useParams();

  useEffect(() => {
    initialize(initialData.items, initialData.boxes, initialData.sender, initialData.recipient);
  }, [initialData]);

  const allItemsPacked = state.items.every((item) => {
    const quantityPacked = state.boxes.reduce((acc, box) => {
      const itemInBox = box.items.find((i) => i.id === item.id);
      return acc + (itemInBox ? itemInBox.quantity : 0);
    }, 0);
    return quantityPacked === item.quantity;
  });

  useDndMonitor({
    onDragEnd: ({ active, over }) => {
      if (!active || !over) return;

      const activeData = active.data.current;
      const overData = over.data.current;
      const quantityToMove = activeData.moveableQuantity;

      if (overData.type !== "Box" && overData.type !== "NewBox") {
        return;
      }

      const fromBoxId = activeData.box?.id || null;
      const toBoxId = overData.box?.id || null;

      moveItem(fromBoxId, toBoxId, activeData.item.id, quantityToMove);
    },
  });

  const mutation = useShipmentUpdate();
  const bookingMutation = useShipmentDeliveryBooking();

  const handleSaveChanges = () => {
    const data = {
      shipment: {
        cartons: state.boxes.map((box) => ({
          // check if numeric box.id, if not, set to null
          id: isNaN(box.id) ? null : box.id,
          length_mm: box.dimensions.length * 10,
          width_mm: box.dimensions.width * 10,
          height_mm: box.dimensions.height * 10,
          weight_g: box.weight * 1000,
          carton_items: box.items.map((item) => ({
            shipment_unit_id: item.id,
            quantity: item.quantity,
          })),
        })),
      },
    };

    mutation.mutate({
      id: shipmentId,
      data,
    });
    console.log("Save Changes");
  };

  const handleBooking = () => {
    bookingMutation.mutate({
      id: shipmentId,
    });
    console.log("Book Delivery");
  };

  return (
    <div className="p-1.5">
      <div className="grid grid-cols-3 gap-2">
        <OrderDetails />
      </div>
      <div className="flex items-center justify-between">
        {allItemsPacked ? (
          <>
            <button
              onClick={handleSaveChanges}
              className="btn btn-primary"
              disabled={mutation.isLoading}
            >
              {" "}
              Save Changes{" "}
            </button>
            <button
              onClick={handleBooking}
              className="btn btn-primary"
              disabled={bookingMutation.isLoading}
            >
              {" "}
              Book{" "}
            </button>
          </>
        ) : (
          <WarningBadge message="Please pack all items before saving changes" />
        )}
      </div>
      <div className="grid grid-cols-7 gap-6 2xl:gap-8">
        <ShipmentPlanner />
      </div>
    </div>
  );
};

const OrderDetails = () => {
  const { state } = useShipment();

  const sender = state.sender;
  const recipient = state.recipient;

  return (
    <>
      <div>
        <h2 className="heading-sm">Order Details</h2>
        <p>Order #1234</p>
        <p>Order Date: 2021-10-01</p>
        <p>Delivery Date: 2021-10-08</p>
        <p>Order Total: $123.45</p>
        <p>Order Status: Pending</p>
      </div>
      <div>
        <h2 className="heading-sm">Sender</h2>
        <p>{sender.name}</p>
        <p>{sender.address1}</p>
        <p className="uppercase">
          {sender.city} {sender.subdivision_code} {sender.postal_code}
        </p>
        <p>{sender.country}</p>
      </div>
      <div>
        <h2 className="heading-sm">Recipient</h2>
        <p>{recipient.name}</p>
        <p>{recipient.address1}</p>
        <p className="uppercase">
          {recipient.city} {recipient.subdivision_code} {recipient.postal_code}
        </p>
        <p>{recipient.country}</p>
      </div>
    </>
  );
};

const ShipmentPlanner = () => {
  const { state, unpackBox } = useShipment();

  const onSubmit = (e) => {
    e.preventDefault();
    // log data
    console.log(e);
    console.log("Submit");
  };

  return (
    <>
      <div className="col-span-2 min-h-96">
        <div className="flex h-full flex-col">
          <h2 className="heading-sm py-4">Items to Ship</h2>
          <div className="h-full space-y-2 rounded-lg border border-gray-200 bg-gray-100 p-2 shadow-inner 2xl:p-4">
            {state.items.map((item) => (
              <SidebarUnitDraggable key={`sidebar-item-${item.id}`} item={item} />
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-5">
        <div className="flex h-full flex-grow flex-col">
          <h2 className="heading-sm py-4">Packing Plan</h2>
          <div className="h-full space-y-4 rounded-lg border border-gray-200 bg-gray-100 p-2 shadow-inner 2xl:p-4">
            <div className="grid grid-cols-2 gap-2 2xl:grid-cols-3 2xl:gap-4">
              {state.boxes.map((box, index) => (
                <Box
                  key={`box:${box.id}`}
                  box={box}
                  position={index + 1}
                  onUnpack={() => unpackBox(box.id)}
                />
              ))}
              <NewBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const WeightBadge = ({ weight }) => {
  return (
    <span className="rounded bg-slate-100 p-1 text-xs font-medium text-slate-700/80">
      <span className="font-semibold">{weight}</span>
      <span className="ml-0.5 text-slate-700/50">kg</span>
    </span>
  );
};

const DimensionsBadge = ({ dimensions }) => {
  return (
    <span className="rounded bg-slate-100 p-1 text-xs font-medium text-slate-700/80">
      <span className="font-semibold">{dimensions.length}</span>
      <span className="mx-0.5 text-slate-700/50">x</span>
      <span className="font-semibold">{dimensions.width}</span>
      <span className="mx-0.5 text-slate-700/50">x</span>
      <span className="font-semibold">{dimensions.height}</span>
      <span className="ml-0.5 text-slate-700/50">cm</span>
    </span>
  );
};

const DimensionsInput = ({ dimensions, onChange }) => {
  const handleChange = (e, dimension) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    onChange({ ...dimensions, [dimension]: value });
  };

  return (
    <div className="flex items-center space-x-1">
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        value={dimensions.length}
        onChange={(e) => handleChange(e, "length")}
        className="input-with-addon w-16 rounded-sm border border-gray-200 p-1 text-end text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-orange-500"
        placeholder="L"
      />
      <span className="text-xs text-gray-500">x</span>
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        value={dimensions.width}
        onChange={(e) => handleChange(e, "width")}
        className="input-with-addon w-16 rounded-sm border border-gray-200 p-1 pr-2 text-end text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-orange-500"
        placeholder="W"
      />
      <span className="text-xs text-gray-500">x</span>
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        value={dimensions.height}
        onChange={(e) => handleChange(e, "height")}
        className="input-with-addon focus:ring-range-500 w-16 rounded-sm border border-gray-200 p-1 text-end text-sm focus:border-transparent focus:outline-none focus:ring-2"
        placeholder="H"
      />
      <span className="text-xs text-gray-500">cm</span>
    </div>
  );
};

const WeightInput = ({ weight, onChange }) => {
  return (
    <div className="flex items-center space-x-1">
      <input
        type="number"
        min={0.1}
        max={999.9}
        step={0.1}
        // examples values: 1, 1.5, 0.25, 10, 10.2
        title="Enter a value between 0 and 999 with up to two decimal places."
        //pattern="^\d{0,3}(\.\d{0,2})?$"
        value={weight}
        required
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="input-with-addon h=1 text-ell focus:outline-noneafter: w-16 rounded-sm border border-gray-200 p-1 text-right text-sm focus:border-transparent focus:ring-2 focus:ring-orange-500"
        placeholder="..."
      />
      <span className="text-xs text-gray-500">kg</span>
    </div>
  );
};

const WarningBadge = ({ message }) => {
  return (
    <span className="rounded bg-rose-100 p-1 text-xs font-semibold text-rose-700">{message}</span>
  );
};

const Box = ({ box, position, onUnpack }) => {
  const { state, unpackItem, updateBoxDimensions, updateBoxWeight } = useShipment();
  const { setNodeRef, isOver, active } = useDroppable({
    id: `box:${box.id}`,
    data: { box, type: "Box" },
  });

  const isSameBox = active?.data?.current?.box?.id === box.id;

  // check if active item is already in this box, if so, don't show the overlay
  const activeItem = active?.data?.current?.item;
  const activeItemInBox = box.items.find((item) => item.id === activeItem?.id);

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "group/box flex min-h-72 flex-col rounded-lg border border-orange-500/25 bg-white p-2 shadow-sm 2xl:p-4",
        isOver && !isSameBox && "ring-2 ring-orange-500/50 ring-offset-2"
      )}
    >
      <div className="mb-2 grid grid-cols-[auto,3fr,auto] items-center gap-2 2xl:mb-4">
        <h3 className="heading-sm col-start-1 !text-orange-900">Box {position}</h3>
        <div className="col-span-3 col-start-1 flex items-center justify-between">
          <DimensionsInput
            dimensions={box.dimensions}
            onChange={(d) => updateBoxDimensions(box.id, d)}
          />
          <WeightInput weight={box.weight} onChange={(w) => updateBoxWeight(box.id, w)} />
        </div>
        <div className="col-start-3 row-start-1">
          <button
            onClick={onUnpack}
            className="invisible h-6 w-6 rounded-sm bg-rose-100 text-xs font-semibold text-rose-700 group-hover/box:visible"
          >
            x
          </button>
        </div>
        {/* <button
          onClick={onUnpack}
          className=""
        >
          x
        </button> */}
      </div>
      <div className="h-full space-y-1.5 rounded-md border border-orange-200 bg-orange-50 bg-none px-2 py-1.5 shadow-inner shadow-orange-100">
        <div className="grid grid-cols-[auto,2fr,1fr,auto] items-center gap-y-1.5">
          {box.items.map((item) => (
            <BoxUnit
              key={`box-${box.id}-item-${item.id}`}
              box={box}
              item={item}
              highlight={activeItem?.id === item.id}
              onUnpack={() => unpackItem(box.id, item.id)}
            />
          ))}
        </div>
        {isOver && !isSameBox && !activeItemInBox && (
          <div className="w-full">
            <div className="h-[48px] rounded-sm border-2 border-dashed border-orange-500/50 bg-none">
              <div className="flex h-full items-center justify-center">
                <h3 className="heading-sm !text-orange-900/75">Drop item to pack in box</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const NewBox = () => {
  const droppable = useDroppable({
    id: `new-box`,
    data: {
      type: "NewBox",
    },
  });
  return (
    <div
      ref={droppable.setNodeRef}
      className={cn(
        "flex min-h-72 flex-col items-center justify-center rounded-lg border-2 border-dashed border-orange-900/50 bg-none p-4",
        droppable.isOver && "border-orange-500/50 ring-2 ring-orange-500/50 ring-offset-2 "
      )}
    >
      <div className="mb-4 flex w-1/2 items-center justify-between text-center">
        <h3 className="heading-sm !text-orange-900/75">Drag item to pack in a new box</h3>
      </div>
    </div>
  );
};

const SidebarUnit = ({ item }) => {
  const { state } = useShipment();
  const quantityPacked = state.boxes.reduce((acc, box) => {
    const itemInBox = box.items.find((i) => i.id === item.id);
    return acc + (itemInBox ? itemInBox.quantity : 0);
  }, 0);

  return (
    <div className="flex items-center justify-between p-1">
      <div className="px-1 text-sm/8 font-semibold text-gray-900">
        {item.name}
        <div className="flex space-x-2">
          <WeightBadge weight={item.weight} />
          <DimensionsBadge dimensions={item.dimensions} />
        </div>
      </div>
      <div
        className={cn(
          "flex items-center justify-between rounded-sm bg-gray-100 px-2 py-1 font-medium text-gray-700",
          quantityPacked >= item.quantity && "bg-emerald-100 text-emerald-950",
          quantityPacked > 0 && quantityPacked < item.quantity && "bg-orange-100 text-orange-950"
        )}
      >
        <p className="flex items-center self-center">
          {quantityPacked}
          <span
            className={cn(
              "px-1 text-xs font-semibold text-gray-500",
              quantityPacked >= item.quantity && "text-emerald-700",
              quantityPacked > 0 && quantityPacked < item.quantity && "text-orange-700"
            )}
          >
            /
          </span>
          {item.quantity}
        </p>
      </div>
    </div>
  );
};

const SidebarUnitDraggable = ({ item }) => {
  const { state } = useShipment();
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const quantityPacked = state.boxes.reduce((acc, box) => {
    const itemInBox = box.items.find((i) => i.id === item.id);
    return acc + (itemInBox ? itemInBox.quantity : 0);
  }, 0);

  const moveableQuantity = item.quantity - quantityPacked;

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: `sidebar-unit-${item.id}`,
    data: { item, type: "SidebarUnit", moveableQuantity: selectedQuantity },
    disabled: quantityPacked >= item.quantity,
  });

  useDndMonitor({
    onDragCancel: (e) => {
      // ignore unless we are dragging this item
      if (e.active.data.current.item.id !== item.id) {
        return;
      }
      setSelectedQuantity(1);
    },
    onDragEnd: (e) => {
      // ignore unless we are dragging this item
      if (e.active.data.current.item.id !== item.id) {
        return;
      }
      setSelectedQuantity(1);
    },
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "relative cursor-grab rounded-sm border border-indigo-500/25 bg-white shadow-sm",
        isDragging && "ring-2 ring-indigo-500",
        quantityPacked >= item.quantity && "cursor-pointer border-0",
        selectedQuantity > 1 && "ring-2 ring-indigo-500"
      )}
      {...attributes}
      {...listeners}
      onClick={(e) => {
        // shift + click to move all
        if (e.shiftKey) {
          setSelectedQuantity(moveableQuantity);
        } else if (e.ctrlKey) {
          setSelectedQuantity(1);
        } else {
          setSelectedQuantity((prev) => (prev + 1) % (moveableQuantity + 1) || 1);
        }
      }}
    >
      <SidebarUnit item={item} />
      {/* Selected Quantity Popout */}
      {selectedQuantity > 1 && (
        <div className="absolute -right-2.5 -top-2.5 flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500 text-center text-xs font-semibold text-indigo-50">
          <span className="mr-[1px] text-indigo-300">x</span>
          <span className="mr-0.5">{selectedQuantity}</span>
        </div>
      )}
    </div>
  );
};

const SidebarUnitDragOverlay = ({ item, quantity }) => {
  return (
    <div
      className={cn(
        "cursor-grab rounded-sm border border-indigo-500/25 bg-white shadow-sm ring-2 ring-indigo-500"
      )}
    >
      <SidebarUnit item={item} />
      {quantity > 0 && (
        <div className="absolute -right-2.5 -top-2.5 flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500 text-center text-xs font-semibold text-white">
          <span>{quantity}</span>
        </div>
      )}
    </div>
  );
};

const BoxUnit = ({ box, item, onUnpack, highlight }) => {
  const { state } = useShipment();

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: `box-${box.id}-unit-${item.id}`,
    data: { item, box, type: "BoxUnit", moveableQuantity: item.quantity },
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "group/box-item col-span-4 grid grid-cols-subgrid items-center gap-2 rounded-sm border border-orange-200 bg-white p-2 shadow-sm shadow-orange-100",
        isDragging && "ring-2 ring-orange-500/50",
        highlight && "ring-2 ring-orange-500"
      )}
      {...attributes}
      {...listeners}
    >
      {item.id % 2 == 0 && (
        <FontAwesomeIcon
          icon={faDiamondExclamation}
          className="col-start-1 h-5 w-5 text-yellow-500"
          style={{
            "--fa-primary-color": "#111111",
          }}
        />
      )}
      <span className="col-start-2 text-sm font-semibold">
        {state.items.find((i) => i.id === item.id).name}
      </span>
      <span className="col-start-3">
        <span className="rounded bg-slate-100 p-1 text-sm font-medium text-slate-700/80">
          <span className="mr-0.5 text-slate-700/50">x</span>
          <span className="font-semibold">{item.quantity}</span>
        </span>
      </span>
      <button
        onClick={onUnpack}
        className="invisible col-start-4 h-6 w-6 rounded-sm bg-rose-100 text-xs font-semibold text-rose-700 group-hover/box-item:visible"
      >
        x
      </button>
    </div>
  );
};

const BoxUnitDragOverlay = ({ box, item }) => {
  const { state } = useShipment();
  const quantityRemaining =
    item.quantity -
    state.boxes.reduce((acc, b) => {
      const itemInBox = b.items.find((i) => i.id === item.id);
      return acc + (itemInBox ? itemInBox.quantity : 0);
    }, 0);

  return (
    <div className="rounded-sm border border-orange-500/50 bg-white p-2 shadow-sm shadow-orange-100">
      {state.items.find((i) => i.id === item.id).name} (x{item.quantity}){" "}
      <small>{quantityRemaining}</small>
    </div>
  );
};

const DragOverlayWrapper = () => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedQuantity, setDraggedQuantity] = useState(0);

  useDndMonitor({
    onDragStart: (event) => {
      const dragItem = event.active;
      setDraggedItem(dragItem);

      if (!dragItem) {
        return;
      }

      if (dragItem?.data?.current?.type === "SidebarUnit") {
        console.log("event", event);
        console.log("ctrl_key", event.ctrlKey);

        setDraggedQuantity(dragItem.data.current.moveableQuantity);
      }
    },
    onDragCancel: () => {
      setDraggedItem(null);
      setDraggedQuantity(0);
    },
    onDragEnd: () => {
      setDraggedItem(null);
      setDraggedQuantity(0);
    },
  });

  if (!draggedItem) {
    return null;
  }

  const isSidebarUnit = draggedItem.data?.current?.type === "SidebarUnit";
  const isBoxUnit = draggedItem.data?.current?.type === "BoxUnit";

  let node = <div>Unknown</div>;

  if (isSidebarUnit) {
    node = (
      <SidebarUnitDragOverlay item={draggedItem.data.current.item} quantity={draggedQuantity} />
    );
  }

  if (isBoxUnit) {
    node = (
      <BoxUnitDragOverlay box={draggedItem.data.current.box} item={draggedItem.data.current.item} />
    );
  }

  return <DragOverlay>{node}</DragOverlay>;
};
