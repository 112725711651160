/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Partbot Distributor API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Id,
  Shipment,
  ShipmentUpdate200,
  ShipmentUpdateInput,
  Shipments,
  ShipmentsListParams,
  ShipmentsStatistics200Item,
  ShipmentsStatisticsParams
} from '.././models'
import { customInstance } from '.././axios-instance';
import type { ErrorType } from '.././axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



/**
 * List all shipments for a location
 * @summary List Shipments
 */
export const shipmentsList = (
    params?: ShipmentsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Shipments[]>(
      {url: `/shipments`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getShipmentsListQueryKey = (params?: ShipmentsListParams,) => {
    return [`/shipments`, ...(params ? [params]: [])] as const;
    }

    
export const getShipmentsListQueryOptions = <TData = Awaited<ReturnType<typeof shipmentsList>>, TError = ErrorType<unknown>>(params?: ShipmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof shipmentsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShipmentsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof shipmentsList>>> = ({ signal }) => shipmentsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof shipmentsList>>, TError, TData> & { queryKey: QueryKey }
}

export type ShipmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsList>>>
export type ShipmentsListQueryError = ErrorType<unknown>

/**
 * @summary List Shipments
 */
export const useShipmentsList = <TData = Awaited<ReturnType<typeof shipmentsList>>, TError = ErrorType<unknown>>(
 params?: ShipmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof shipmentsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShipmentsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get statistics for shipments by location IDs
 * @summary Get Shipments Statistics
 */
export const shipmentsStatistics = (
    params?: ShipmentsStatisticsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ShipmentsStatistics200Item[]>(
      {url: `/shipments/statistics`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getShipmentsStatisticsQueryKey = (params?: ShipmentsStatisticsParams,) => {
    return [`/shipments/statistics`, ...(params ? [params]: [])] as const;
    }

    
export const getShipmentsStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof shipmentsStatistics>>, TError = ErrorType<unknown>>(params?: ShipmentsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof shipmentsStatistics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShipmentsStatisticsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof shipmentsStatistics>>> = ({ signal }) => shipmentsStatistics(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof shipmentsStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type ShipmentsStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsStatistics>>>
export type ShipmentsStatisticsQueryError = ErrorType<unknown>

/**
 * @summary Get Shipments Statistics
 */
export const useShipmentsStatistics = <TData = Awaited<ReturnType<typeof shipmentsStatistics>>, TError = ErrorType<unknown>>(
 params?: ShipmentsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof shipmentsStatistics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShipmentsStatisticsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get shipment by ID for a location
 * @summary Get Shipment
 */
export const shipmentById = (
    id: Id,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Shipment>(
      {url: `/shipments/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getShipmentByIdQueryKey = (id: Id,) => {
    return [`/shipments/${id}`] as const;
    }

    
export const getShipmentByIdQueryOptions = <TData = Awaited<ReturnType<typeof shipmentById>>, TError = ErrorType<unknown>>(id: Id, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof shipmentById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShipmentByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof shipmentById>>> = ({ signal }) => shipmentById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof shipmentById>>, TError, TData> & { queryKey: QueryKey }
}

export type ShipmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentById>>>
export type ShipmentByIdQueryError = ErrorType<unknown>

/**
 * @summary Get Shipment
 */
export const useShipmentById = <TData = Awaited<ReturnType<typeof shipmentById>>, TError = ErrorType<unknown>>(
 id: Id, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof shipmentById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShipmentByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update a shipment
 * @summary Update Shipment
 */
export const shipmentUpdate = (
    id: Id,
    shipmentUpdateInput: ShipmentUpdateInput,
 ) => {
      
      
      return customInstance<ShipmentUpdate200>(
      {url: `/shipments/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: shipmentUpdateInput
    },
      );
    }
  


export const getShipmentUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shipmentUpdate>>, TError,{id: Id;data: ShipmentUpdateInput}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof shipmentUpdate>>, TError,{id: Id;data: ShipmentUpdateInput}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof shipmentUpdate>>, {id: Id;data: ShipmentUpdateInput}> = (props) => {
          const {id,data} = props ?? {};

          return  shipmentUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ShipmentUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shipmentUpdate>>>
    export type ShipmentUpdateMutationBody = ShipmentUpdateInput
    export type ShipmentUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Update Shipment
 */
export const useShipmentUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shipmentUpdate>>, TError,{id: Id;data: ShipmentUpdateInput}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof shipmentUpdate>>,
        TError,
        {id: Id;data: ShipmentUpdateInput},
        TContext
      > => {

      const mutationOptions = getShipmentUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a delivery booking for a shipment
 * @summary Create Delivery Booking
 */
export const shipmentDeliveryBooking = (
    id: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/shipments/${id}/delivery_booking`, method: 'POST'
    },
      );
    }
  


export const getShipmentDeliveryBookingMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shipmentDeliveryBooking>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof shipmentDeliveryBooking>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof shipmentDeliveryBooking>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  shipmentDeliveryBooking(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ShipmentDeliveryBookingMutationResult = NonNullable<Awaited<ReturnType<typeof shipmentDeliveryBooking>>>
    
    export type ShipmentDeliveryBookingMutationError = ErrorType<unknown>

    /**
 * @summary Create Delivery Booking
 */
export const useShipmentDeliveryBooking = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shipmentDeliveryBooking>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof shipmentDeliveryBooking>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getShipmentDeliveryBookingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    