import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";

import Table from "../../UI/components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { VehicleStatusFilter, VehicleSourceFilter, VehiclePropFilter } from "./Filters";

export default function VehiclesTable() {
  const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleSelectedRows = useCallback((rowIds) => {
    setSelectedVehicleIds(rowIds);
  });

  const fetchData = useCallback(async ({ pageIndex, pageSize, sortBy, filters, globalFilter }) => {
    console.log("SORT", sortBy);
    console.log("FILTERS", filters);
    console.log("GLOBAL FILTER", globalFilter);

    let params = {
      page: pageIndex + 1,
      per: pageSize,
    };

    if (sortBy.length > 0) {
      params["sort"] = sortBy.map((s) => `${s.id} ${s.desc ? "desc" : "asc"}`).join(", ");
    }

    if (globalFilter) {
      params["query"] = globalFilter;
    }

    if (filters && filters.length > 0) {
      params["filters"] = filters.map((s) => `${s.id}=${s.value}`).join(",");
    }

    const requestConfig = {
      responseType: "json",
      headers: ReactOnRails.authenticityHeaders(),
      params: {
        section: "body",
      },
    };
    setLoading(true);

    const result = await fetch(
      "/admin/vehicles/datatable.json?" + new URLSearchParams(params),
      requestConfig
    );
    const data = await result.json();
    setData(data.data);

    setPageCount(Math.ceil(data.meta.total_count / 1));
    setTotalCount(data.meta.total_count);

    setLoading(false);
  }, []);

  const sourceColor = (source) => {
    const sources = {
      TecDoc: {
        badge: "bg-blue-100 text-blue-800",
        dot: "text-blue-400",
      },
      OSCAR: {
        badge: "bg-rose-100 text-rose-800",
        dot: "text-rose-400",
      },
      Partbot: {
        badge: "bg-indigo-100 text-indigo-800",
        dot: "text-indigo-400",
      },
      default: {
        badge: "bg-gray-100 text-gray-100",
        dot: "text-gray-400",
      },
    };
    return sources[source] || sources["default"];
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        disableFilters: true,
        disableSortBy: true,
        disableResizing: true,
        width: 30,
        id: "status",
        accessor: "status_icon",
        Cell: function ({ value, row }) {
          return (
            (row.original.status == "archived" && (
              <span className={`inline-flex items-center text-gray-300`} title="Archived">
                <FontAwesomeIcon icon={faArchive} />
              </span>
            )) ||
            (row.original.new_vehicle && (
              <span className={`inline-flex items-center`} title="New">
                <span
                  className="bg-success-100 flex h-2.5 w-2.5 items-center justify-center rounded-full"
                  aria-hidden="true"
                >
                  <span className="bg-success-400 h-1.5 w-1.5 rounded-full"></span>
                </span>
              </span>
            )) ||
            (row.original.updated_vehicle && (
              <span className={`inline-flex items-center`} title="Recently Updated">
                <span className={`inline-flex items-center`} title="Recently Updated">
                  <span
                    className="flex h-2.5 w-2.5 items-center justify-center rounded-full bg-indigo-100"
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-indigo-400"></span>
                  </span>
                </span>
              </span>
            ))
          );
        },
      },
      {
        Header: "Make",
        accessor: "make",
        disableFilters: true,
        width: 100,
      },
      {
        Header: "Model",
        accessor: "model",
        disableFilters: true,
        width: 100,
      },
      {
        Header: "Series",
        disableFilters: true,
        accessor: "series", // accessor is the "key" in the data
        width: 90,
      },
      {
        Header: "Years",
        disableFilters: true,
        id: "year_to",
        accessor: "year_range", // accessor is the "key" in the data
        width: 130,
      },
      {
        Header: "Body",
        disableFilters: true,
        accessor: "body", // accessor is the "key" in the data
        width: 80,
      },
      {
        Header: "Variant",
        disableFilters: true,
        accessor: "variant", // accessor is the "key" in the data
        width: 80,
      },
      {
        Header: "Engine",
        disableFilters: true,
        accessor: "engine", // accessor is the "key" in the data
        width: 80,
      },
      {
        Header: "Capacity (L)",
        disableFilters: true,
        accessor: "engine_capacity", // accessor is the "key" in the data
        width: 50,
        className: "text-right",
      },
      {
        Header: "Power (kW)",
        disableFilters: true,
        accessor: "kw_power", // accessor is the "key" in the data
        width: 50,
        className: "text-right",
      },

      // {
      //   Header: "Cylinders",
      //   disableFilters: true,
      //   accessor: "cylinders", // accessor is the "key" in the data
      //   width: 40,
      //   className: "text-right",
      // },
      {
        Header: "Fuel Type",
        disableFilters: true,
        accessor: "fuel_type", // accessor is the "key" in the data
        width: 50,
      },
      {
        Header: "Drive Type",
        disableFilters: true,
        accessor: "drive_type", // accessor is the "key" in the data
        width: 50,
      },
      {
        Header: "Doors",
        disableFilters: true,
        accessor: "doors", // accessor is the "key" in the data
        width: 50,
      },
      {
        Header: "Transmission",
        disableFilters: true,
        accessor: "transmission", // accessor is the "key" in the data
        width: 50,
      },
      {
        Header: "Source",
        accessor: "source",
        disableFilters: true,
        width: 105,
        disableResizing: true,
        Cell: function ({ value, row }) {
          let color = sourceColor(value);

          return (
            <span className={`flex justify-start text-[11px] text-gray-500`}>
              <span className={`mr-1.5 inline-flex items-center`}>
                <svg
                  className={`mr-1.5 h-1.5 w-1.5 ${color.dot}`}
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx={4} cy={4} r={3} />
                </svg>
                {value}
              </span>
              <span className="`inline-flex items-center ">
                {row.original.tecdoc_id || row.original.autoinfo_id}
              </span>
            </span>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        disableFilters: true,
        disableSortBy: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        disableResizing: true,
        Cell: function ({ value, row }) {
          return (
            <div className="relative flex items-center justify-items-center">
              <div
                className={` absolute right-0 mr-5 mt-[17px] py-2.5 pl-3 text-right ${
                  row.index % 2 == 0 ? "bg-white" : "bg-gray-50"
                }`}
              >
                <a
                  className="text-indigo-600 hover:text-indigo-900"
                  href={`/admin/vehicles/${value}/edit`}
                >
                  Edit
                </a>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="border-b border-gray-200 bg-white p-5 shadow-sm sm:rounded-md">
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalItems={totalCount}
        initialPageSize={25}
        useRowSelection={false}
        rowProps={(row) => ({
          className: row.original.status === "archived" ? "text-gray-300" : "",
        })}
        additionalFilters={[
          (props) => (
            <div key="vehicle-table-filters" className="flex gap-2">
              <VehiclePropFilter title="Make" prop="make" {...props} />
              <VehiclePropFilter title="Model" prop="model" {...props} />
              <VehiclePropFilter title="Series" prop="series" {...props} />
              <VehiclePropFilter title="Body" prop="body" {...props} />
              <VehicleStatusFilter {...props} />
              <VehicleSourceFilter {...props} />
            </div>
          ),
        ]}
      />
    </div>
  );
}
