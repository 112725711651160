import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const Pagination = ({ pagination }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const currentPageSize = parseInt(searchParams.get("page_size") || "10", 10);

  const pageSizes = [1, 2, 5, 10, 25, 50, 100];

  const getPageUrl = (page, pageSize = currentPageSize) => {
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    params.set("page_size", pageSize);
    return `${location.pathname}?${params.toString()}`;
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value, 10);
    navigate(getPageUrl(1, newPageSize));
  };

  const renderPageLinks = () => {
    const pages = [];
    const totalPages = pagination.total_pages;
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 1);

    if (start > 1) {
      pages.push(
        <Link
          key="first"
          to={getPageUrl(1)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          1
        </Link>
      );
      if (start > 2) {
        pages.push(
          <span
            key="ellipsis1"
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
          >
            ...
          </span>
        );
      }
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <Link
          key={i}
          to={getPageUrl(i)}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 focus:z-20 ${
            i === currentPage
              ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              : "text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
          }`}
        >
          {i}
        </Link>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pages.push(
          <span
            key="ellipsis2"
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
          >
            ...
          </span>
        );
      }
      pages.push(
        <Link
          key="last"
          to={getPageUrl(totalPages)}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          {totalPages}
        </Link>
      );
    }

    return pages;
  };

  // return (
  //   <div className="mt-4 flex items-center justify-end space-x-2 text-sm text-gray-600">
  //     <div className="flex items-center space-x-1">
  //       <Link
  //         to={getPageUrl(1)}
  //         className={`rounded px-3 py-1 transition ${
  //           currentPage === 1
  //             ? "cursor-not-allowed bg-gray-200 text-gray-400"
  //             : "bg-indigo-500 text-white hover:bg-indigo-600"
  //         }`}
  //         onClick={(e) => currentPage === 1 && e.preventDefault()}
  //       >
  //         First
  //       </Link>
  //       <Link
  //         to={getPageUrl(currentPage - 1)}
  //         className={`rounded px-3 py-1 transition ${
  //           pagination.previous_page_allowed
  //             ? "bg-indigo-500 text-white hover:bg-indigo-600"
  //             : "cursor-not-allowed bg-gray-200 text-gray-400"
  //         }`}
  //         onClick={(e) => !pagination.previous_page_allowed && e.preventDefault()}
  //       >
  //         Previous
  //       </Link>
  //     </div>
  //     <div className="flex items-center space-x-1">{renderPageLinks()}</div>
  //     <div className="flex items-center space-x-1">
  //       <Link
  //         to={getPageUrl(currentPage + 1)}
  //         className={`rounded px-3 py-1 transition ${
  //           pagination.next_page_allowed
  //             ? "bg-indigo-500 text-white hover:bg-indigo-600"
  //             : "cursor-not-allowed bg-gray-200 text-gray-400"
  //         }`}
  //         onClick={(e) => !pagination.next_page_allowed && e.preventDefault()}
  //       >
  //         Next
  //       </Link>
  //       <Link
  //         to={getPageUrl(pagination.total_pages)}
  //         className={`rounded px-3 py-1 transition ${
  //           currentPage === pagination.total_pages
  //             ? "cursor-not-allowed bg-gray-200 text-gray-400"
  //             : "bg-indigo-500 text-white hover:bg-indigo-600"
  //         }`}
  //         onClick={(e) => currentPage === pagination.total_pages && e.preventDefault()}
  //       >
  //         Last
  //       </Link>
  //     </div>
  //     <span className="mx-2 whitespace-nowrap">
  //       Page {currentPage} of {pagination.total_pages}
  //     </span>
  //     <select
  //       value={currentPageSize}
  //       onChange={handlePageSizeChange}
  //       className="rounded border border-gray-300 px-2 py-1 text-sm"
  //     >
  //       {pageSizes.map((size) => (
  //         <option key={size} value={size}>
  //           {size} per page
  //         </option>
  //       ))}
  //     </select>
  //   </div>
  // );

  return (
    <div className="-m-4 flex items-center justify-between border-t border-gray-200 bg-white px-4 pt-4 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to={getPageUrl(currentPage - 1)}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            pagination.previous_page_allowed ? "" : "cursor-not-allowed bg-gray-200 text-gray-400"
          }`}
          onClick={(e) => !pagination.previous_page_allowed && e.preventDefault()}
        >
          Previous
        </Link>
        <Link
          to={getPageUrl(currentPage + 1)}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            pagination.next_page_allowed ? "" : "cursor-not-allowed bg-gray-200 text-gray-400"
          }`}
          onClick={(e) => !pagination.next_page_allowed && e.preventDefault()}
        >
          Next
        </Link>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Page <span className="font-medium">{currentPage}</span> of{" "}
            <span className="font-medium">{pagination.total_pages}</span>
          </p>
        </div>
        <div>
          <nav
            aria-label="Pagination"
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          >
            <Link
              to={getPageUrl(currentPage - 1)}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 ${
                pagination.previous_page_allowed
                  ? "hover:bg-gray-50"
                  : "cursor-not-allowed bg-gray-100 text-gray-400"
              }`}
              onClick={(e) => !pagination.previous_page_allowed && e.preventDefault()}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </Link>
            {renderPageLinks()}
            <Link
              to={getPageUrl(currentPage + 1)}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-700 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0 ${
                pagination.next_page_allowed
                  ? "hover:bg-gray-50"
                  : "cursor-not-allowed bg-gray-100 text-gray-400"
              }`}
              onClick={(e) => !pagination.next_page_allowed && e.preventDefault()}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
