import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";

import OnboardImage1 from "../../../packs/images/shopify-onboard-01.svg";
import OnboardImage2 from "../../../packs/images/shopify-onboard-02.svg";
import OnboardImage3 from "../../../packs/images/shopify-onboard-03.svg";
import OnboardImage4 from "../../../packs/images/shopify-onboard-04.svg";

import { Button } from "../../UI/components";
import { faLongArrowRight } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { faCheck, faLongArrowLeft } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const onboardSteps = [
  {
    step: 1,
    title: "Step 1: Find Products",
    description:
      "Browse the Partbot Marketplace to find the products you want to import to your Shopify store. Filter by Brands and Product Types.",
    image: OnboardImage1,
  },
  {
    step: 2,
    title: "Step 2: Add Products to Import List",
    description:
      "Select the products you want to import to your store. Click Add to Import List where they'll be ready for you to review.",
    image: OnboardImage2,
  },
  {
    step: 3,
    title: "Step 3: Import Products to Shopify",
    description:
      "On the Import List page, you can review and edit the products you've selected. Click Import to send the products to your store.",
    image: OnboardImage3,
  },
  {
    step: 4,
    title: "Request Fulfilment",
    description:
      "When you receive an order on your store, you can request fulfilment from Partbot to dropship the products to your customer.",
    image: OnboardImage4,
    color: "emerald",
  },
];

export const GettingStartedModal = ({ isOpen, user }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(isOpen);

  // const stepsContainer = useRef();
  const [stepsContainer, setStepsContainerRef] = useState(null);

  const handleClose = async () => {
    //
  };

  const completeOnboarding = async () => {
    setIsLoading(true);

    try {
      const result = await axios({
        method: "post",
        url: `/shopify-onboard-complete/${user.id}`,
        responseType: "json",
        headers: ReactOnRails.authenticityHeaders(),
      });

      // console.log("result", result);

      setTimeout(() => {
        setIsLoading(false);
        setModalOpen(false);
      }, 500);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    if (!stepsContainer) {
      return null;
    }

    let ticking = false;
    let timer = null;

    const onContainerScroll = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(function () {
            console.log("scroll stopped");

            // get the current step based on the position the user stopped scrolling at
            const scrollPos = stepsContainer?.scrollLeft + stepsContainer?.offsetLeft || 0;

            console.log("scrollPos", scrollPos);

            const scrolledStep = onboardSteps.find((step, index) => {
              console.log(document.getElementById(`step-${step.step}`).offsetLeft);
              return scrollPos === document.getElementById(`step-${step.step}`).offsetLeft;
            });

            if (scrolledStep) {
              setCurrentStep(scrolledStep.step);
            }
          }, 150);

          ticking = false;
        });

        ticking = true;
        console.log("stopped scrolling");
      }
    };

    if (stepsContainer) {
      stepsContainer.addEventListener("scroll", onContainerScroll);
    }

    return () => {
      clearTimeout(timer);
      stepsContainer.removeEventListener("scroll", onContainerScroll);
    };
  }, [stepsContainer]);

  const scrollToStep = (step) => {
    const stepPosition = document.getElementById(`step-${step || 1}`)?.offsetLeft;

    stepsContainer?.scroll({
      top: 0,
      left: stepPosition,
      behavior: "smooth",
    });
  };

  const nextStep = () => {
    const step = currentStep + 1;
    setCurrentStep(Math.min(step, onboardSteps.length));
    scrollToStep(step);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      const step = currentStep - 1;
      setCurrentStep(step);
      scrollToStep(step);
    }
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white p-8 text-left align-bottom shadow-xl transition-all sm:align-middle">
                <div>
                  <div className="py-5">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-3xl font-extrabold leading-6 tracking-tight text-gray-900"
                    >
                      Get Started
                    </Dialog.Title>
                    <div className="mt-5">
                      <p className="text-center text-sm text-gray-500">
                        Now that you've connected your store, you can start importing products.
                      </p>
                    </div>
                    {/* Carousel of steps */}
                    <div
                      ref={setStepsContainerRef}
                      id="steps-container"
                      className="mb-0 mt-0 flex w-full snap-x gap-8 overflow-x-auto py-5"
                    >
                      {onboardSteps.map((step) => (
                        <div
                          id={`step-${step.step}`}
                          key={step.step}
                          className={classNames(
                            "min-h-[250px] w-full flex-shrink-0 snap-center rounded-lg",
                            step.color === "emerald" ? "bg-emerald-100" : "bg-indigo-100"
                          )}
                        >
                          <div className="flex flex-col justify-between pb-2 text-center">
                            <div>
                              <img src={step.image} className="w-full" />
                            </div>
                            <div
                              className={classNames(
                                "-mt-32 space-y-2 px-8 pb-8 pt-20",
                                step.color === "emerald"
                                  ? "bg-gradient-to-b from-emerald-100/0 via-emerald-100 to-emerald-100"
                                  : "bg-gradient-to-b from-indigo-100/0 via-indigo-100 to-indigo-100"
                              )}
                            >
                              <div className="text-lg font-bold tracking-tight">{step.title}</div>
                              <div
                                className={classNames(
                                  "text-sm",
                                  step.color === "emerald" ? "text-emerald-600" : "text-indigo-500"
                                )}
                              >
                                {step.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="btn-group mt-1 flex w-full">
                  <button
                    type="button"
                    disabled={currentStep === 1}
                    className="btn btn-lg btn-light w-full"
                    onClick={prevStep}
                  >
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                    <span>Back</span>
                  </button>
                  <Button
                    showLoading={isLoading}
                    label={currentStep === onboardSteps.length ? "Finish" : "Next"}
                    icon={currentStep === onboardSteps.length ? faCheck : faLongArrowRight}
                    type="button"
                    className={classNames(
                      "btn btn-lg w-full",
                      currentStep === onboardSteps.length && "btn-success"
                    )}
                    onClick={currentStep === onboardSteps.length ? completeOnboarding : nextStep}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
