import { faUserPlus } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import Toggle from "../UI/components/Toggle";
import { Toaster, toast } from "react-hot-toast";
import Api from "./api";
import { useDebouncedCallback } from "use-debounce";

export default function MarketplaceSettings({
  team,
  definitions,
  import_listings_count,
  app_contexts,
  merchant_products_count,
}) {
  const marketplaceAPI = new Api(team.api_key, location);

  const updateSetting = async (setting, value) => {
    marketplaceAPI.updateTeamSetting(team, setting, value).then((data) => {
      if (data.success) {
        toast.success("Settings updated", { id: "success" });
      } else {
        toast.error("An error occured", { id: "error" });
      }
    });
  };

  const isDisabled = (setting) => {
    return (
      definitions.find((d) => d.namespace === setting.namespace && d.key === setting.key)
        .enabled === false
    );
  };

  const getSettingDisplayName = (setting) => {
    return definitions.find((d) => d.namespace === setting.namespace && d.key === setting.key).name;
  };

  const getDefinitionDescription = (setting) => {
    return definitions.find((d) => d.namespace === setting.namespace && d.key === setting.key)
      .description;
  };

  const groupSettings = (settings) => {
    const grouped = settings.reduce((acc, setting) => {
      if (setting.group) {
        if (!acc[setting.group]) {
          acc[setting.group] = [];
        }
        acc[setting.group].push(setting);
      } else {
        acc[setting.key] = setting;
      }
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const getSettingComponent = (setting) => {
    switch (setting.type) {
      case "input":
        return (
          <SettingInput
            setting={setting}
            label={getSettingDisplayName(setting)}
            updateSetting={updateSetting}
          />
        );
      case "boolean":
        return (
          <label
            className="flex items-center space-x-2 font-medium"
            title={
              isDisabled(setting)
                ? "Setting currently unavailable"
                : getDefinitionDescription(setting)
            }
          >
            <Toggle
              label={getSettingDisplayName(setting)}
              checked={setting.value}
              listingId={setting}
              onChangeCallback={(value) => updateSetting(setting, value)}
              disabled={isDisabled(setting)}
            />
            <span>{getSettingDisplayName(setting)}</span>
          </label>
        );
      case "select":
        return (
          <div>
            <label
              className="flex items-center space-x-2 font-medium"
              title={
                isDisabled(setting)
                  ? "Setting currently unavailable"
                  : getDefinitionDescription(setting)
              }
            >
              {getSettingDisplayName(setting)}
            </label>
            <select
              disabled={isDisabled(setting)}
              title={
                isDisabled(setting)
                  ? "Setting currently unavailable"
                  : getDefinitionDescription(setting)
              }
              value={setting.value}
              onChange={(e) => updateSetting(setting, e.target.value)}
              className="disabled:opacity-30"
            >
              {definitions
                .find((d) => d.namespace === setting.namespace && d.key === setting.key)
                .validations[0].value.map((option) => (
                  <option key={option} value={option}>
                    {/* remove underscores from option and capitalize */}
                    {option
                      .replace(/_/g, " ")
                      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))}
                  </option>
                ))}
            </select>
          </div>
        );

      default:
        return (
          <div className="text-danger bg-danger-100 text-danger-600 rounded px-2 py-2 text-center text-xs">
            Error: Setting Component Not Found
          </div>
        );
    }
  };

  return (
    <>
      <Header section="Settings" app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar
              team={team}
              importListCount={import_listings_count}
              merchantProductsCount={merchant_products_count}
            />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Settings</h2>
            <div className="hidden w-full rounded bg-purple-100 px-4 py-3 text-center text-xs text-purple-500">
              Please Note: Some settings are currently not available to change during the Partbot
              Beta.
            </div>
            <div className="grid grid-cols-3 gap-5">
              <div className="w-full space-y-5 rounded-lg bg-white p-8 shadow">
                <div className="space-y-2 pb-4">
                  <h3 className="text-xl font-bold">Product</h3>
                  <p className="text-xs text-gray-500">
                    Manage how Partbot updates your synced products when catalogue data is changed
                    by the manufacturer.
                  </p>
                </div>
                {groupSettings(
                  team.settings
                    .filter((setting) => setting.namespace === "product")
                    .sort((a, b) => {
                      if (a.type < b.type) {
                        return -1;
                      }
                    })
                ).map((settingOrGroup) =>
                  Array.isArray(settingOrGroup) ? (
                    <div className="flex flex-row space-x-5" key={settingOrGroup[0].group}>
                      {settingOrGroup.map((setting) => (
                        <div className="space-y-3" key={setting.key}>
                          {getSettingComponent(setting)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="space-y-3" key={settingOrGroup.key}>
                      {getSettingComponent(settingOrGroup)}
                    </div>
                  )
                )}
              </div>
              {/* Inventory Settings */}
              <div className="w-full space-y-5 rounded-lg bg-white p-8 shadow">
                <div className="space-y-2 pb-4">
                  <h3 className="text-xl font-bold">Inventory</h3>
                  <p className="text-xs text-gray-500">
                    Manage how Partbot updates your synced products when inventory levels change.
                  </p>
                </div>
                {team.settings
                  .filter((setting) => setting.namespace === "inventory")
                  .sort((a, b) => {
                    if (a.type < b.type) {
                      return -1;
                    }
                  })
                  .map((setting) => (
                    <div className="space-y-3" key={setting.key}>
                      {getSettingComponent(setting)}
                    </div>
                  ))}
              </div>

              {/* Pricing Settings */}
              <div className="w-full space-y-5 rounded-lg bg-white p-8 shadow">
                <div className="space-y-2 pb-4">
                  <h3 className="text-xl font-bold">Pricing</h3>
                  <p className="text-xs text-gray-500">
                    Manage how Partbot updates your synced products when prices are changed by
                    Distributors.
                  </p>
                </div>
                {team.settings
                  .filter((setting) => setting.namespace === "pricing")
                  .sort((a, b) => {
                    if (a.type < b.type) {
                      return -1;
                    }
                  })
                  .map((setting) => (
                    <div className="space-y-3" key={setting.key}>
                      {getSettingComponent(setting)}
                    </div>
                  ))}
              </div>

              {/* Data Settings */}
              <div className="w-full space-y-5 rounded-lg bg-white p-8 shadow">
                <div className="space-y-2 pb-4">
                  <h3 className="text-xl font-bold">Data</h3>
                  <p className="text-xs text-gray-500">
                    Configure additional data options for your connected products.
                  </p>
                </div>
                {team.settings
                  .filter((setting) => setting.namespace === "data")
                  .sort((a, b) => {
                    if (a.type < b.type) {
                      return -1;
                    }
                  })
                  .map((setting) => (
                    <div className="space-y-3" key={setting.key}>
                      {getSettingComponent(setting)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
}

const SettingInput = ({ setting, updateSetting, label }) => {
  const [value, setValue] = useState(setting.value || "");

  const debouncedUpdateSetting = useDebouncedCallback((v) => {
    updateSetting(setting, v);
  }, 300);

  useEffect(() => {
    setValue(setting.value);
  }, [setting.value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedUpdateSetting(newValue);
  };

  return (
    <div>
      <label className="flex items-center space-x-2 font-medium">{label}</label>
      <input type="text" value={value || ""} onChange={handleChange} />
    </div>
  );
};
